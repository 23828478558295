.Carousel {
  .feature {
    font-style: italic;

    em {
      font-weight: bold;
      font-style: normal;
      display: block;
    }

    .feature-image {
      img {
        width: 60px;
        height: auto;
      }
    }
  }
}
