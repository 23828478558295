.ResponsibleGamingContainer.OffCanvas {
  .canvas-content {
    .canvas-header + div {
      text-align: center;
    }
  }
}

.ResponsibleGamingWidget {
  &__timespan {
    &-limit-selector {
      .button {
        padding: 0;
        background: var(--primary-color);
        box-shadow: none;
        &:hover {
          background: var(--primary-color-hover);
        }
      }
      .tg-input {
        margin: 0;
        .Input input {
          width: 100%;
        }
      }
    }
    &-box {
      padding: 20px 8px;
    }
    &-title,
    &-row,
    &-limit-selector {
      margin: 8px;
    }
  }
}

.PayNPlay {
  .SetLimitsStep {
    &__summary-item,
    &__notice {
      color: var(--white);
      padding: 0.25em;
    }
    &__actions {
      background-color: var(--accordion);

      .button {
        max-width: 60%;
        margin: 10px auto;
      }
    }
  }
}
