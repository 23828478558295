.jurisdiction-mga .SportsContainer .main-content-container {
  @media (max-width: 992px) {
    margin-top: calc(var(--header-height-mobile) + var(--sticky-footer-height));
  }
}

.jurisdiction-sga .BasePage:not(.IndexContainerPnp) .main-content-container {
  @media (max-width: 992px) {
    margin-top: calc(
      var(--header-height-mobile) + var(--rg-bar-height) +
        var(--sticky-footer-height)
    );
  }
}

.main-content-container {
  @media (max-width: 992px) {
    margin-top: calc(var(--header-height-mobile) + var(--sticky-footer-height));
  }
}

body[data-install-app='true'].page-casino,
body[data-install-app='true'].page-live-casino {
  .InstallApp {
    margin-top: var(--header-height);
  }
}
@media (max-width: 992px) {
  body[data-install-app='true'].page-casino,
  body[data-install-app='true'].page-live-casino {
    .InstallApp {
      margin-top: calc(
        var(--header-height-mobile) + var(--sticky-footer-height)
      );
    }
  }
}

body[data-install-app='true'].jurisdiction-sga.page-casino,
body[data-install-app='true'].jurisdiction-sga.page-live-casino {
  .InstallApp {
    margin-top: calc(var(--header-height) + var(--rg-bar-height));
  }
}
@media (max-width: 992px) {
  body[data-install-app='true'].jurisdiction-sga.page-casino,
  body[data-install-app='true'].jurisdiction-sga.page-live-casino {
    .InstallApp {
      margin-top: calc(
        var(--header-height-mobile) + var(--sticky-footer-height) +
          var(--rg-bar-height)
      );
    }
  }
}

.SettingsTab {
  bottom: 3em;
}

@media (max-width: 900px) {
  .ContentPreviewContainer {
    height: 390px;
  }
}
