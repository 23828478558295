.ContentPreviewContainer {
  form {
    display: flex;
    align-items: center;

    @media (max-width: 900px) {
      align-items: flex-start;
    }
  }
}
