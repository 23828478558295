/* Put all brand specific css and logos/backgrounds/icons in this skin folder. */

@font-face {
  font-family: IBM Plex sans;
  font-style: normal;
  font-weight: 300;
  src: local('IBM Plex sans light 300'), local('IBMPlexSans-Light'),
    url('/skin/fonts/IBMPlexSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: IBM Plex sans;
  src: url('/skin/fonts/IBMPlexSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: IBM Plex sans;
  font-style: normal;
  font-weight: 400;
  src: local('IBM Plex sans'), local('IBMPlexSans-Regular'),
    url('/skin/fonts/IBMPlexSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: IBM Plex sans;
  font-style: normal;
  font-weight: 500;
  src: local('IBM Plex medium'), local('IBMPlexSans-Medium'),
    url('/skin/fonts/IBMPlexSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: IBM Plex sans;
  font-style: normal;
  font-weight: 600;
  src: url('/skin/fonts/IBMPlexSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: IBM Plex sans;
  src: local('IBM Plex sans bold'), local('IBMPlexSans-Bold'),
    url('/skin/fonts/IBMPlexSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: IBM Plex sans;
  src: local('IBM Plex sans bold'), local('IBMPlexSans-Bold'),
    url('/skin/fonts/IBMPlexSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'icomoon';
  src:  url('/skin/icomoon/icomoon.eot?qtmlsm');
  src:  url('/skin/icomoon/icomoon.eot?qtmlsm#iefix') format('embedded-opentype'),
    url('/skin/icomoon/icomoon.ttf?qtmlsm') format('truetype'),
    url('/skin/icomoon/icomoon.woff?qtmlsm') format('woff'),
    url('/skin/icomoon/icomoon.svg?qtmlsm#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-play-solid:before {
  content: "\e900";
  color: #efefef;
}

@media (max-width: 992px) {

.jurisdiction-mga .SportsContainer .main-content-container {
    margin-top: calc(60px + 55px)
}
  }

@media (max-width: 992px) {

.jurisdiction-sga .BasePage:not(.IndexContainerPnp) .main-content-container {
    margin-top: calc(
      60px + 30px +
        55px
    )
}
  }

@media (max-width: 992px) {

.main-content-container {
    margin-top: calc(60px + 55px)
}
  }

body[data-install-app='true'].page-casino .InstallApp, body[data-install-app='true'].page-live-casino .InstallApp {
    margin-top: 75px;
  }

@media (max-width: 992px) {
    body[data-install-app='true'].page-casino .InstallApp, body[data-install-app='true'].page-live-casino .InstallApp {
      margin-top: calc(
        60px + 55px
      );
    }
}

body[data-install-app='true'].jurisdiction-sga.page-casino .InstallApp, body[data-install-app='true'].jurisdiction-sga.page-live-casino .InstallApp {
    margin-top: calc(75px + 30px);
  }

@media (max-width: 992px) {
    body[data-install-app='true'].jurisdiction-sga.page-casino .InstallApp, body[data-install-app='true'].jurisdiction-sga.page-live-casino .InstallApp {
      margin-top: calc(
        60px + 55px +
          30px
      );
    }
}

.SettingsTab {
  bottom: 3em;
}

@media (max-width: 900px) {
  .ContentPreviewContainer {
    height: 390px;
  }
}

.AccordionWidget {
  color: #ffffff;
}

.AccordionWidget .AccordionItem__header {
      background-color: #242424;
    }

.AccordionWidget .AccordionItem__header:hover {
        background-color: #242424;
      }

.AccordionWidget .AccordionItem__header__expand-icon {
        color: #ffffff;
      }

.AccordionWidget .AccordionItem__header .info-amount {
        color: #717171;
      }

@media (max-width: 540px) {

.AccordionWidget .AccordionItem__header .info-amount {
          right: 50px
      }
        }

.AccordionWidget .AccordionItem__header__tooltop {
        border-radius: 10px;
      }

.AccordionWidget .AccordionItem__content {
      background-color: #242424;
    }

.AccordionWidget .AccordionItem__content .table {
        background: #242424;
      }

.AccordionWidget .AccordionItem__content .pagination {
        display: flex;
      }

.AccordionWidget .AccordionItem__content .navigation {
        display: flex;
        margin-top: 10px;
      }

.AccordionWidget .AccordionItem__content .navigation button {
          width: 50%;
          margin: 5px;
          padding: 0 1em;
        }

.AccordionWidget .AccordionItem .tg-list .row .info .info-item {
            line-height: 20px;
            color: var(--tertiary-text-color);
          }

.AccordionWidget .AccordionItem .tg-list .row .info .info-item:first-child {
              align-items: flex-start;
            }

.AccordionWidget .AccordionItem .tg-list .row .info .info-item:first-child > *:first-child {
                  font-weight: bold;
                  color: var(--secondary-text-color);
                }

.AccordionWidget .AccordionItem .tg-list .row .info .info-item:last-child {
              align-items: flex-end;
            }

.AccordionWidget .AccordionItem .tg-list .row .info .info-item:last-child > *:first-child {
                  font-weight: bold;
                  color: var(--secondary-text-color);
                }

.Banners .slick-slider .slick-list .slick-track .slick-slide {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

.Banners .slick-slider .slick-list .slick-track .slick-slide .background:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(
    rgba(27, 27, 27, 1) 0%,
    transparent 20%,
    transparent 85%,
    rgba(27, 27, 27, 1) 100%
  );
      z-index: 1;
      pointer-events: none;
    }

.Banners .slick-slider .slick-list .slick-track .slick-slide .content {
      left: 40px !important;
      width: 100%;
      max-width: 1900px;
      padding: 0 20px;
      position: relative;
      margin-bottom: 60px;
      /* reset styles from tg-base */
      top: auto !important;
      bottom: auto !important;
    }

.Banners .slick-slider .slick-list .slick-track .slick-slide .content h1 {
        font-size: 35px;
      }

.Banners .slick-slider .slick-list .slick-track .slick-slide .content h1,
      .Banners .slick-slider .slick-list .slick-track .slick-slide .content h2,
      .Banners .slick-slider .slick-list .slick-track .slick-slide .content h3,
      .Banners .slick-slider .slick-list .slick-track .slick-slide .content h4 {
        margin: 0;
        line-height: normal;
      }

@media (max-width: 992px) {

.Banners .slick-slider .slick-list .slick-track .slick-slide .content {
        left: 20px !important
    }
      }

.BasePage .error-background .error-content {
      width: initial;
    }

@media (max-width: 540px) {
      .BasePage .error-background h1 {
        font-size: 30px;
      }
    }

@media (min-width: 993px) {

#fc_frame:not(.fc-open) {
    display: initial
}
  }

.BonusContainer .bonus-code-form button.primary,
  .BonusContainer .bonus-code-form button.primary-button,
  .BonusContainer .bonus-code-form button.secondary,
  .BonusContainer .bonus-code-form button.secondary-button,
  .BonusContainer .bonus-code-form button.tertiary,
  .BonusContainer .bonus-code-form button.tertiary-button,
  .limit-container .timespan-selector .button.primary,
  .limit-container .timespan-selector .button.primary-button,
  .limit-container .timespan-selector .button.secondary,
  .limit-container .timespan-selector .button.secondary-button,
  .limit-container .timespan-selector .button.tertiary,
  .limit-container .timespan-selector .button.tertiary-button,
  .button.primary,
  .button.primary-button,
  .button.secondary,
  .button.secondary-button,
  .button.tertiary,
  .button.tertiary-button,
  button.primary,
  button.primary-button,
  button.secondary,
  button.secondary-button,
  button.tertiary,
  button.tertiary-button {
    transition: none;
    border-radius: 5px;
    -webkit-transform: skew(-15deg);
            transform: skew(-15deg);
    text-transform: uppercase;
  }

.BonusContainer .bonus-code-form button.primary svg circle, .BonusContainer .bonus-code-form button.primary-button svg circle, .BonusContainer .bonus-code-form button.secondary svg circle, .BonusContainer .bonus-code-form button.secondary-button svg circle, .BonusContainer .bonus-code-form button.tertiary svg circle, .BonusContainer .bonus-code-form button.tertiary-button svg circle, .limit-container .timespan-selector .button.primary svg circle, .limit-container .timespan-selector .button.primary-button svg circle, .limit-container .timespan-selector .button.secondary svg circle, .limit-container .timespan-selector .button.secondary-button svg circle, .limit-container .timespan-selector .button.tertiary svg circle, .limit-container .timespan-selector .button.tertiary-button svg circle, .button.primary svg circle, .button.primary-button svg circle, .button.secondary svg circle, .button.secondary-button svg circle, .button.tertiary svg circle, .button.tertiary-button svg circle, button.primary svg circle, button.primary-button svg circle, button.secondary svg circle, button.secondary-button svg circle, button.tertiary svg circle, button.tertiary-button svg circle {
      stroke: #ffffff;
    }

.BonusContainer .bonus-code-form button.primary > *, .BonusContainer .bonus-code-form button.primary-button > *, .BonusContainer .bonus-code-form button.secondary > *, .BonusContainer .bonus-code-form button.secondary-button > *, .BonusContainer .bonus-code-form button.tertiary > *, .BonusContainer .bonus-code-form button.tertiary-button > *, .limit-container .timespan-selector .button.primary > *, .limit-container .timespan-selector .button.primary-button > *, .limit-container .timespan-selector .button.secondary > *, .limit-container .timespan-selector .button.secondary-button > *, .limit-container .timespan-selector .button.tertiary > *, .limit-container .timespan-selector .button.tertiary-button > *, .button.primary > *, .button.primary-button > *, .button.secondary > *, .button.secondary-button > *, .button.tertiary > *, .button.tertiary-button > *, button.primary > *, button.primary-button > *, button.secondary > *, button.secondary-button > *, button.tertiary > *, button.tertiary-button > * {
      -webkit-transform: skew(15deg);
              transform: skew(15deg);
    }

.BonusContainer .bonus-code-form button.primary,
  .BonusContainer .bonus-code-form button.primary-button,
  .limit-container .timespan-selector .button.primary,
  .limit-container .timespan-selector .button.primary-button,
  .button.primary,
  .button.primary-button,
  button.primary,
  button.primary-button {
    color: #ffffff;
    background: linear-gradient(
    45deg,
    rgba(0, 161, 154, 1) 13%,
    rgba(0, 161, 154, 1) 32%,
    rgba(0, 161, 154, 1) 61%,
    rgba(75, 163, 141, 1) 96%
  );
  }

.BonusContainer .bonus-code-form button.primary:hover, .BonusContainer .bonus-code-form button.primary-button:hover, .limit-container .timespan-selector .button.primary:hover, .limit-container .timespan-selector .button.primary-button:hover, .button.primary:hover, .button.primary-button:hover, button.primary:hover, button.primary-button:hover {
      background: #1c635f;
    }

.BonusContainer .bonus-code-form button.secondary,
  .BonusContainer .bonus-code-form button.secondary-button,
  .limit-container .timespan-selector .button.secondary,
  .limit-container .timespan-selector .button.secondary-button,
  .button.secondary,
  .button.secondary-button,
  button.secondary,
  button.secondary-button {
    color: #ffffff;
    background: linear-gradient(
    45deg,
    rgba(0, 161, 154, 1) 13%,
    rgba(0, 161, 154, 1) 32%,
    rgba(0, 161, 154, 1) 61%,
    rgba(75, 163, 141, 1) 96%
  );
  }

.BonusContainer .bonus-code-form button.secondary:hover, .BonusContainer .bonus-code-form button.secondary-button:hover, .limit-container .timespan-selector .button.secondary:hover, .limit-container .timespan-selector .button.secondary-button:hover, .button.secondary:hover, .button.secondary-button:hover, button.secondary:hover, button.secondary-button:hover {
      background: #1c635f;
    }

.BonusContainer .bonus-code-form button.tertiary,
  .BonusContainer .bonus-code-form button.tertiary-button,
  .limit-container .timespan-selector .button.tertiary,
  .limit-container .timespan-selector .button.tertiary-button,
  .button.tertiary,
  .button.tertiary-button,
  button.tertiary,
  button.tertiary-button {
    color: #ffffff;
    box-shadow: inset 0 0 0 2px #ffffff;
    background: none;
  }

.BonusContainer .bonus-code-form button.tertiary:hover, .BonusContainer .bonus-code-form button.tertiary-button:hover, .limit-container .timespan-selector .button.tertiary:hover, .limit-container .timespan-selector .button.tertiary-button:hover, .button.tertiary:hover, .button.tertiary-button:hover, button.tertiary:hover, button.tertiary-button:hover {
      background: #242424;
    }

.BonusContainer .bonus-code-form button.icon, .limit-container .timespan-selector .button.icon, .button.icon, button.icon {
    padding: 0;
  }

.BonusContainer .bonus-code-form button.icon .icon, .limit-container .timespan-selector .button.icon .icon, .button.icon .icon, button.icon .icon {
      padding: 0 5px;
    }

.ConfirmContainer .Popup__content .ConfirmContainer__actions .tertiary.button,
.LimitConfirmation .button-wrapper .tertiary.button{	
  color: #ffffff;	
  background: linear-gradient(
    45deg,
    rgba(0, 161, 154, 1) 13%,
    rgba(0, 161, 154, 1) 32%,
    rgba(0, 161, 154, 1) 61%,
    rgba(75, 163, 141, 1) 96%
  );	
  box-shadow: none;
}

.ConfirmContainer .Popup__content .ConfirmContainer__actions .tertiary.button:hover, .LimitConfirmation .button-wrapper .tertiary.button:hover {	
    background: #1c635f;	
  }

.limit-container .timespan-selector .button {
  font-size: 0.8em;
  height: 40px;
}

.limit-container .timespan-selector .button:not(.selected) {
  background: #1c635f;
}

.limit-container .timespan-selector .button:not(.selected):hover {
    background: #00a19a;
  }

.IndexContainerPnp .index-container-full .actions .button {
  box-shadow: 3px 2px 8px 0 rgba(0, 0, 0, 0.3);
  padding: 0;
  width: 370px;
  font-size: 1.85em;
}

.Banners .button,
.ErrorBoundary .button {
  box-shadow: 3px 2px 8px 0 rgba(0, 0, 0, 0.3);
}

.IndexContainerPnp .PayNPlay .question .button {
      -webkit-transform: none;
              transform: none;
      background: none;
      padding: 0;
      font-weight: normal;
      text-transform: none;
      font-size: inherit;
      text-decoration: underline;
    }

.IndexContainerPnp .PayNPlay .question .button span {
        -webkit-transform: none;
                transform: none;
        display: inline;
      }

.MembershipActions .button:hover {
      background: linear-gradient(
    45deg,
    rgba(0, 161, 154, 1) 13%,
    rgba(0, 161, 154, 1) 32%,
    rgba(0, 161, 154, 1) 61%,
    rgba(75, 163, 141, 1) 96%
  );
    }

.Carousel .feature {
    font-style: italic;
  }

.Carousel .feature em {
      font-weight: bold;
      font-style: normal;
      display: block;
    }

.Carousel .feature .feature-image img {
        width: 60px;
        height: auto;
      }

.CasinoCategoryMenu::before {
    display: none;
  }

.CasinoCategoryMenu .categories-menu .category-item {
      margin: 0;
      height: auto;
      display: flex;
      align-items: center;
    }

.CasinoCategoryMenu .categories-menu .category-item.active {
        text-decoration: underline;
      }

.CasinoCategoryMenu .categories-menu .category-item img {
        height: 25px;
        margin-right: 5px;
      }

.CasinoCategoryMenu .casino-search .icon-search,
    .CasinoCategoryMenu .casino-search .icon-times {
      border-radius: 50%;
      background: #00a19a;
    }

.CasinoCategoryMenu .casino-search.active {
      flex-direction: row-reverse;
      justify-content: space-between;
    }

.CasinoCategoryMenu .casino-search .search-input {
      display:flex;
      align-items: center;
    }

.CasinoCategoryMenu .casino-search .search-input .Input__status-line {
          background: none;
        }

.GamePlay .CasinoCategoryMenu .categories-menu {
      color: #ffffff;
    }

.content-container .actual-content {
    margin-top: 30px;
    margin-left: 3%;
  }

.content-container .NavList {
    margin-top: 50px;
  }

.content-container .NavList ul {
      border-radius: 10px;
    }

.ContentPreviewContainer form {
    display: flex;
    align-items: center;
  }

@media (max-width: 900px) {

.ContentPreviewContainer form {
      align-items: flex-start
  }
    }

.Dashboard {
  min-height: calc(100% - 42px);
}

.Dashboard .notifications-badge--right[data-notifications]:not([data-notifications='']):not([data-notifications='0']):after {
    color: #ffffff;
  }

.Dashboard .ItemWithIconAndText:hover .icon {
        -webkit-filter: brightness(1.4);
                filter: brightness(1.4);
      }

.Dashboard .products,
  .Dashboard .profile-links,
  .Dashboard .payment-buttons {
    border-bottom: 1px solid #154341;
  }

.Dashboard .payment-buttons {
    padding-bottom: 20px;
    order: 2;
  }

.Dashboard .profile-links {
    order: 3;
  }

.Dashboard .products {
    order: 4;
  }

.Dashboard .balance {
    order: 1;
    margin-top: 0;
    align-items: center;
  }

.Dashboard .balance .refresh-button,
    .Dashboard .balance .refresh-button.loading {
      display: block;
      background: none;
      color: #ffffff;
    }

.Dashboard .balance .refresh-button svg > *, .Dashboard .balance .refresh-button.loading svg > * {
        stroke: #ffffff;
      }

.Dashboard .support {
    margin-top: auto;
    order: 5;
  }

.Dashboard .navigation {
    order: 6;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
  }

.Dashboard .navigation a {
      text-decoration: none;
      padding: 5px 0;
    }

.Dashboard .navigation a:hover {
        text-decoration: underline;
      }

@media (max-width: 992px) {

.Dashboard .navigation {
      display: flex
  }
    }

.Dashboard .footer {
    border-top: 1px solid #154341;
    padding-top: 20px;
    order: 7;
    min-height: 62px;
  }

.Dashboard .footer .LanguageSelector {
      left: -10px;
    }

@media (max-width: 540px) {
      .Dashboard .payment-buttons .button {
        margin: 0;
      }

        .Dashboard .payment-buttons .button:first-child {
          margin-right: 10px;
        }

    .Dashboard .support {
      padding: 20px 0 20px 0;
    }

    .Dashboard .footer {
      padding: 20px 0 0 0;
    }
  }

.ErrorBoundary {
  padding: 0;
}

.ErrorBoundary .error-message-container {
    text-align: center;
    background: none;
  }

.ErrorBoundary .error-message-container h1 {
      color: #ffffff;
    }

.Features .feature {
    margin: 10px 40px;
    font-style: italic;
  }

.Features .feature em {
      font-weight: bold;
      font-style: normal;
      display: block;
    }

.Features .feature .feature-image img {
        width: 60px;
        height: auto;
      }

.Footer .topSection .container {
      flex-flow: row;
    }

.Footer .LanguageSelector select {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 600;
    }

.Footer .licence {
    flex-wrap: wrap;
  }

.Footer .licence > * {
      margin: 5px;
    }

.Footer .licence div {
      display: flex !important;
    }

.Footer .licence div p a {
        display: flex;
        flex-flow: column;
        text-decoration: none;
      }

.Footer .licence div p a span {
          font-size: 10px;
        }

.Footer .licence div p a svg {
          height: 30px;
          margin-right: 0;
        }

@media (max-width: 992px) {
      .Footer .topSection .container {
        flex-flow: column;
      }
  }

.CasinoGrid__header,
  .CasinoGrid .grid-header,
  .GameGrid__header,
  .GameGrid .grid-header {
    justify-content: unset;
    align-items: center;
    max-width: 94vw;
    margin: 1em auto;
    margin-top: 40px;
  }

.CasinoGrid__header .header-and-icon, .CasinoGrid .grid-header .header-and-icon, .GameGrid__header .header-and-icon, .GameGrid .grid-header .header-and-icon {
      font-weight: 600;
      font-size: 19.6px;
    }

.CasinoGrid__header a, .CasinoGrid .grid-header a, .GameGrid__header a, .GameGrid .grid-header a {
      height: 15px;
      margin: 0 15px;
      text-decoration: none;
    }

@media (max-width: 992px) {

.CasinoGrid__header,
  .CasinoGrid .grid-header,
  .GameGrid__header,
  .GameGrid .grid-header {
      margin: 1em 0 10px;
      padding: 0 10px
  }
    }

.CasinoGrid .slider .slide-item .last-slide a, .GameGrid .slider .slide-item .last-slide a {
        border-radius: 6px;
      }

.CasinoGrid .slider .arrow--show, .GameGrid .slider .arrow--show {
        opacity: 1;
      }

.CasinoGrid .slider .arrow:disabled, .GameGrid .slider .arrow:disabled {
        opacity: 0;
      }

.CasinoGrid .slider .arrow--next, .GameGrid .slider .arrow--next {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        background: linear-gradient(
          to right,
          rgba(0, 0, 0, 0.2) 0%,
          rgba(0, 0, 0, 0.6) 100%
        );
      }

.CasinoGrid .slider .arrow--next:hover, .GameGrid .slider .arrow--next:hover {
          background: linear-gradient(
            to right,
            rgba(0, 0, 0, 0.3) 0%,
            rgba(0, 0, 0, 0.7) 100%
          );
        }

.CasinoGrid .slider .arrow--prev, .GameGrid .slider .arrow--prev {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        background: linear-gradient(
          to left,
          rgba(0, 0, 0, 0.2) 0%,
          rgba(0, 0, 0, 0.6) 100%
        );
      }

.CasinoGrid .slider .arrow--prev:hover, .GameGrid .slider .arrow--prev:hover {
          background: linear-gradient(
            to left,
            rgba(0, 0, 0, 0.3) 0%,
            rgba(0, 0, 0, 0.7) 100%
          );
        }

@media (min-width: 2560px) {

.CasinoGrid .slider .arrow, .GameGrid .slider .arrow {
        width: 80px
    }

        .CasinoGrid .slider .arrow--next, .GameGrid .slider .arrow--next {
          right: 0;
        }

        .CasinoGrid .slider .arrow--prev, .GameGrid .slider .arrow--prev {
          left: 0;
        }
      }

.CasinoGrid {
  margin: 22px auto;
  max-width: unset;
}

.CasinoGrid__grid {
    margin: 22px 50px;
  }

@media (max-width: 992px) {

.CasinoGrid {
    margin: 0
}

    .CasinoGrid__grid {
      margin: 22px 10px;
    }
  }

.casino-promotion .container-max-width {
    margin: 0;
  }

@media (min-width: 992px) {

.casino-promotion .container-max-width {
      padding: 30px 50px
  }
    }

.casino-promotion .summary h1 {
      margin: 0;
    }

.casino-promotion .jackpot-counter {
    margin-bottom: 10px;
  }

.game-history-wrapper .dim-btn.on svg {
      fill: #00a19a;
    }

.game-history-wrapper .home-button {
    border: 2px solid #00a19a;
    border-radius: 25px;
  }

.Game .thumbnail {
    border-radius: 6px;
  }

.Game .thumbnail::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 6px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), transparent);
    }

.Game .favorite-heart {
    z-index: 1;
  }

.Game .jackpot {
    bottom: auto;
  }

.Game .badges {
    top: 5px;
    bottom: initial;
    flex-wrap: wrap;
  }

.Game .badges .badge {
      margin-bottom: 5px;
    }

.Game .badges .badge,
  .Game .ribbon {
    background: linear-gradient(
    45deg,
    rgba(0, 161, 154, 1) 13%,
    rgba(0, 161, 154, 1) 32%,
    rgba(0, 161, 154, 1) 61%,
    rgba(75, 163, 141, 1) 96%
  );
    color: #ffffff;
  }

.Game .ribbon + .badges {
    flex-direction: column;
  }

.Game .game-info {
    position: absolute;
    bottom: 0;
    left: 0;
  }

.Game .game-info .live-casino-info .roulette-numbers {
        z-index: 1;
      }

@media (min-width: 993px) {
      .Game:hover:not(.is-closed) .thumbnail::after {
        font-family: 'icomoon';
        font-size: 45px;
        content: "\e900";
        position: absolute;
        top: 45%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
      }
  }

@media (max-width: 992px) {
      .Game .game-info .provider {
        font-size: 11px;
      }
      .Game .game-info .title {
        font-size: 12px;
      }
      .Game .game-info .live-casino-info {
        margin: 0;
      }
  }

.header-wrapper .Header__topnav {
    height: 75px;
    display: flex;
    align-items: center;
  }

.header-wrapper .Header__topnav .top-nav-menu-item {
      font-weight: bold;
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
    }

.header-wrapper .Header__topnav .top-nav-menu-item.active {
        z-index: 0;
      }

.header-wrapper .Header__topnav .top-nav-menu-item.active::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          bottom: 0;
          left: 0;
          background: linear-gradient(
    45deg,
    rgba(0, 161, 154, 1) 13%,
    rgba(0, 161, 154, 1) 32%,
    rgba(0, 161, 154, 1) 61%,
    rgba(75, 163, 141, 1) 96%
  );
          -webkit-transform: skew(-15deg);
                  transform: skew(-15deg);
          z-index: -1;
        }

.header-wrapper .Header__support {
    margin-right: 40px;
    font-weight: bold;
    font-size: 14px;
  }

.header-wrapper .Header__hamburger .notifications-badge--right[data-notifications]:not([data-notifications='']):not([data-notifications='0']):after {
      color: #ffffff;
    }

@media (max-width: 992px) {
    .header-wrapper .Header__topnav {
      display: none;
    }

    .header-wrapper .Header__logo {
      margin-right: auto;
    }
  }

.header-wrapper .MembershipActions .button.primary {
      background: rgb(255, 255, 255);
      color: rgb(0, 161, 154);
    }

@media (min-width: 992px) and (max-width: 1280px) {

.header-wrapper .MembershipActions .button.primary:not(.user-balance) {
          padding: 0 3em;
          font-size: 1em
      }
        }

.header-wrapper .MembershipActions .button.primary:hover {
        background: rgb(198, 255, 252);
      }

.header-wrapper .MembershipActions .button.primary span {
        font-weight: 800;
        font-size: 1.5em;
        padding: 0.3em;
      }

.header-wrapper .MembershipActions .line {
      -webkit-transform: none;
              transform: none;
      height: 40px;
      border-left: 1px solid rgb(0, 161, 154);
    }

.header-wrapper .MembershipActions .icon-plus {
      padding: 0 10px;
      width: auto;
      height: auto;
    }

.header-wrapper .MembershipActions .WalletButtonSkeleton {
      background: linear-gradient(
    45deg,
    rgba(0, 161, 154, 1) 13%,
    rgba(0, 161, 154, 1) 32%,
    rgba(0, 161, 154, 1) 61%,
    rgba(75, 163, 141, 1) 96%
  );
      -webkit-transform: skew(-15deg);
              transform: skew(-15deg);
    }

.IndexContainerPnp .index__header {
    top: 0px;
  }

.IndexContainerPnp .index-container-full {
    min-height: calc(100vh - 50px);
  }

@media (max-width: 540px) {

.IndexContainerPnp .index-container-full .actions .button {
          width: 90%
      }
        }

.IndexContainerPnp .index-background-active + .fixed-position .brand-description,
  .IndexContainerPnp .index-background-active + .fixed-position .Features,
  .IndexContainerPnp .index-background-active + .fixed-position .Carousel {
    display: none;
  }

.IndexContainerPnp .index__header .index__header__logo {
    width: 140px;
    display: block;
  }

.IndexContainerPnp .index__header .index__header__actions .index__header__actions__menu span, .IndexContainerPnp .index__header .index__header__actions .index__header__actions__home span {
        display: none;
      }

.IndexContainerPnp .index__header .index__header__actions .index__header__actions__menu img,
      .IndexContainerPnp .index__header .index__header__actions .index__header__actions__menu i,
      .IndexContainerPnp .index__header .index__header__actions .index__header__actions__home img,
      .IndexContainerPnp .index__header .index__header__actions .index__header__actions__home i {
        border-radius: 50%;
        border: 2px solid #ffffff;
        height: 36px;
        width: 36px;
        padding: 8px;
      }

.IndexContainerPnp .index__header .index__header__actions .index__header__actions__menu i, .IndexContainerPnp .index__header .index__header__actions .index__header__actions__home i {
        position: relative;
        line-height: 36px;
        font-size: 1.3em;
      }

@media (max-width: 992px) {

.IndexContainerPnp .index__header {
    position: absolute
}
    .IndexContainerPnp .index__header__actions {
      position: fixed;
      right: 10px;
    }
  }

.tg-input .Input, 
  .tg-input .Select, 
  .tg-password-input .Input, 
  .tg-password-input .Select, 
  .tg-phonenumber-input .Input, 
  .tg-phonenumber-input .Select, 
  .tg-select .Input, 
  .tg-select .Select {
    -webkit-transform: skew(-15deg);
            transform: skew(-15deg);
    box-shadow: inset 0 0 0 2px #00a19a;
    border-radius: 5px;
  }

.tg-input .Input input,
    .tg-input .Input select,
    .tg-input .Select input,
    .tg-input .Select select,
    .tg-password-input .Input input,
    .tg-password-input .Input select,
    .tg-password-input .Select input,
    .tg-password-input .Select select,
    .tg-phonenumber-input .Input input,
    .tg-phonenumber-input .Input select,
    .tg-phonenumber-input .Select input,
    .tg-phonenumber-input .Select select,
    .tg-select .Input input,
    .tg-select .Input select,
    .tg-select .Select input,
    .tg-select .Select select {
      background: none;
      text-align: center;
      border: none;
      color: #ffffff;
      -webkit-transform: skew(15deg);
              transform: skew(15deg);
    }

.tg-input .Input input option, .tg-input .Input select option, .tg-input .Select input option, .tg-input .Select select option, .tg-password-input .Input input option, .tg-password-input .Input select option, .tg-password-input .Select input option, .tg-password-input .Select select option, .tg-phonenumber-input .Input input option, .tg-phonenumber-input .Input select option, .tg-phonenumber-input .Select input option, .tg-phonenumber-input .Select select option, .tg-select .Input input option, .tg-select .Input select option, .tg-select .Select input option, .tg-select .Select select option {
        background-color: #1b1b1b;
      }

.tg-input .Input input optgroup, .tg-input .Input select optgroup, .tg-input .Select input optgroup, .tg-input .Select select optgroup, .tg-password-input .Input input optgroup, .tg-password-input .Input select optgroup, .tg-password-input .Select input optgroup, .tg-password-input .Select select optgroup, .tg-phonenumber-input .Input input optgroup, .tg-phonenumber-input .Input select optgroup, .tg-phonenumber-input .Select input optgroup, .tg-phonenumber-input .Select select optgroup, .tg-select .Input input optgroup, .tg-select .Input select optgroup, .tg-select .Select input optgroup, .tg-select .Select select optgroup {
        background-color: #1b1b1b;
      }

.tg-input .Input input::-webkit-input-placeholder, .tg-input .Input select::-webkit-input-placeholder, .tg-input .Select input::-webkit-input-placeholder, .tg-input .Select select::-webkit-input-placeholder, .tg-password-input .Input input::-webkit-input-placeholder, .tg-password-input .Input select::-webkit-input-placeholder, .tg-password-input .Select input::-webkit-input-placeholder, .tg-password-input .Select select::-webkit-input-placeholder, .tg-phonenumber-input .Input input::-webkit-input-placeholder, .tg-phonenumber-input .Input select::-webkit-input-placeholder, .tg-phonenumber-input .Select input::-webkit-input-placeholder, .tg-phonenumber-input .Select select::-webkit-input-placeholder, .tg-select .Input input::-webkit-input-placeholder, .tg-select .Input select::-webkit-input-placeholder, .tg-select .Select input::-webkit-input-placeholder, .tg-select .Select select::-webkit-input-placeholder {
        color: #00a19a;
      }

.tg-input .Input input::placeholder, .tg-input .Input select::placeholder, .tg-input .Select input::placeholder, .tg-input .Select select::placeholder, .tg-password-input .Input input::placeholder, .tg-password-input .Input select::placeholder, .tg-password-input .Select input::placeholder, .tg-password-input .Select select::placeholder, .tg-phonenumber-input .Input input::placeholder, .tg-phonenumber-input .Input select::placeholder, .tg-phonenumber-input .Select input::placeholder, .tg-phonenumber-input .Select select::placeholder, .tg-select .Input input::placeholder, .tg-select .Input select::placeholder, .tg-select .Select input::placeholder, .tg-select .Select select::placeholder {
        color: #00a19a;
      }

.tg-input .Input__leading-lane .icon, 
    .tg-input .Input__trailing-lane .icon, 
    .tg-input .Select__leading-lane .icon, 
    .tg-input .Select__trailing-lane .icon, 
    .tg-password-input .Input__leading-lane .icon, 
    .tg-password-input .Input__trailing-lane .icon, 
    .tg-password-input .Select__leading-lane .icon, 
    .tg-password-input .Select__trailing-lane .icon, 
    .tg-phonenumber-input .Input__leading-lane .icon, 
    .tg-phonenumber-input .Input__trailing-lane .icon, 
    .tg-phonenumber-input .Select__leading-lane .icon, 
    .tg-phonenumber-input .Select__trailing-lane .icon, 
    .tg-select .Input__leading-lane .icon, 
    .tg-select .Input__trailing-lane .icon, 
    .tg-select .Select__leading-lane .icon, 
    .tg-select .Select__trailing-lane .icon {
      -webkit-transform: skew(15deg);
              transform: skew(15deg);
    }

.tg-input .Input__trailing-lane > *, .tg-input .Select__trailing-lane > *, .tg-password-input .Input__trailing-lane > *, .tg-password-input .Select__trailing-lane > *, .tg-phonenumber-input .Input__trailing-lane > *, .tg-phonenumber-input .Select__trailing-lane > *, .tg-select .Input__trailing-lane > *, .tg-select .Select__trailing-lane > * {
        margin-right: 5px;
      }

.tg-input .Input input::-webkit-outer-spin-button,
    .tg-input .Input input::-webkit-inner-spin-button,
    .tg-input .Select input::-webkit-outer-spin-button,
    .tg-input .Select input::-webkit-inner-spin-button,
    .tg-password-input .Input input::-webkit-outer-spin-button,
    .tg-password-input .Input input::-webkit-inner-spin-button,
    .tg-password-input .Select input::-webkit-outer-spin-button,
    .tg-password-input .Select input::-webkit-inner-spin-button,
    .tg-phonenumber-input .Input input::-webkit-outer-spin-button,
    .tg-phonenumber-input .Input input::-webkit-inner-spin-button,
    .tg-phonenumber-input .Select input::-webkit-outer-spin-button,
    .tg-phonenumber-input .Select input::-webkit-inner-spin-button,
    .tg-select .Input input::-webkit-outer-spin-button,
    .tg-select .Input input::-webkit-inner-spin-button,
    .tg-select .Select input::-webkit-outer-spin-button,
    .tg-select .Select input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

.tg-input .Input input[type='number'], .tg-input .Select input[type='number'], .tg-password-input .Input input[type='number'], .tg-password-input .Select input[type='number'], .tg-phonenumber-input .Input input[type='number'], .tg-phonenumber-input .Select input[type='number'], .tg-select .Input input[type='number'], .tg-select .Select input[type='number'] {
      -moz-appearance: textfield;
    }

.tg-input .Input input:-webkit-autofill,
    .tg-input .Input input:-webkit-autofill:hover,
    .tg-input .Input input:-webkit-autofill:focus,
    .tg-input .Input textarea:-webkit-autofill,
    .tg-input .Input textarea:-webkit-autofill:hover,
    .tg-input .Input textarea:-webkit-autofill:focus,
    .tg-input .Input select:-webkit-autofill,
    .tg-input .Input select:-webkit-autofill:hover,
    .tg-input .Input select:-webkit-autofill:focus,
    .tg-input .Select input:-webkit-autofill,
    .tg-input .Select input:-webkit-autofill:hover,
    .tg-input .Select input:-webkit-autofill:focus,
    .tg-input .Select textarea:-webkit-autofill,
    .tg-input .Select textarea:-webkit-autofill:hover,
    .tg-input .Select textarea:-webkit-autofill:focus,
    .tg-input .Select select:-webkit-autofill,
    .tg-input .Select select:-webkit-autofill:hover,
    .tg-input .Select select:-webkit-autofill:focus,
    .tg-password-input .Input input:-webkit-autofill,
    .tg-password-input .Input input:-webkit-autofill:hover,
    .tg-password-input .Input input:-webkit-autofill:focus,
    .tg-password-input .Input textarea:-webkit-autofill,
    .tg-password-input .Input textarea:-webkit-autofill:hover,
    .tg-password-input .Input textarea:-webkit-autofill:focus,
    .tg-password-input .Input select:-webkit-autofill,
    .tg-password-input .Input select:-webkit-autofill:hover,
    .tg-password-input .Input select:-webkit-autofill:focus,
    .tg-password-input .Select input:-webkit-autofill,
    .tg-password-input .Select input:-webkit-autofill:hover,
    .tg-password-input .Select input:-webkit-autofill:focus,
    .tg-password-input .Select textarea:-webkit-autofill,
    .tg-password-input .Select textarea:-webkit-autofill:hover,
    .tg-password-input .Select textarea:-webkit-autofill:focus,
    .tg-password-input .Select select:-webkit-autofill,
    .tg-password-input .Select select:-webkit-autofill:hover,
    .tg-password-input .Select select:-webkit-autofill:focus,
    .tg-phonenumber-input .Input input:-webkit-autofill,
    .tg-phonenumber-input .Input input:-webkit-autofill:hover,
    .tg-phonenumber-input .Input input:-webkit-autofill:focus,
    .tg-phonenumber-input .Input textarea:-webkit-autofill,
    .tg-phonenumber-input .Input textarea:-webkit-autofill:hover,
    .tg-phonenumber-input .Input textarea:-webkit-autofill:focus,
    .tg-phonenumber-input .Input select:-webkit-autofill,
    .tg-phonenumber-input .Input select:-webkit-autofill:hover,
    .tg-phonenumber-input .Input select:-webkit-autofill:focus,
    .tg-phonenumber-input .Select input:-webkit-autofill,
    .tg-phonenumber-input .Select input:-webkit-autofill:hover,
    .tg-phonenumber-input .Select input:-webkit-autofill:focus,
    .tg-phonenumber-input .Select textarea:-webkit-autofill,
    .tg-phonenumber-input .Select textarea:-webkit-autofill:hover,
    .tg-phonenumber-input .Select textarea:-webkit-autofill:focus,
    .tg-phonenumber-input .Select select:-webkit-autofill,
    .tg-phonenumber-input .Select select:-webkit-autofill:hover,
    .tg-phonenumber-input .Select select:-webkit-autofill:focus,
    .tg-select .Input input:-webkit-autofill,
    .tg-select .Input input:-webkit-autofill:hover,
    .tg-select .Input input:-webkit-autofill:focus,
    .tg-select .Input textarea:-webkit-autofill,
    .tg-select .Input textarea:-webkit-autofill:hover,
    .tg-select .Input textarea:-webkit-autofill:focus,
    .tg-select .Input select:-webkit-autofill,
    .tg-select .Input select:-webkit-autofill:hover,
    .tg-select .Input select:-webkit-autofill:focus,
    .tg-select .Select input:-webkit-autofill,
    .tg-select .Select input:-webkit-autofill:hover,
    .tg-select .Select input:-webkit-autofill:focus,
    .tg-select .Select textarea:-webkit-autofill,
    .tg-select .Select textarea:-webkit-autofill:hover,
    .tg-select .Select textarea:-webkit-autofill:focus,
    .tg-select .Select select:-webkit-autofill,
    .tg-select .Select select:-webkit-autofill:hover,
    .tg-select .Select select:-webkit-autofill:focus {
      border: 2px solid #00a19a;
      -webkit-text-fill-color: #ffffff;
      -webkit-box-shadow: 0 0 0px 1000px #242424 inset;
      -webkit-transform: none;
              transform: none;
      border-radius: 5px;
    }

.casino-search .tg-input .Input {
  box-shadow: none;
}

.casino-search .tg-input .Input input {
    margin-left: 0;
  }

.LanguageSelector {
  margin: 0;
}

.LanguageSelector .tg-select .Select {
      box-shadow: none;
    }

@media (max-width: 992px) {

.LanguageSelector .tg-select .Select select {
          font-size: 12px !important
      }
        }

.LanguageSelector .tg-select .Select__status-line {
        background: none;
        height: 0px;
      }

.LanguageSelector .tg-select .Select .Input__trailing-lane {
        color: white;
      }

.LanguageSelector .tg-select .Select .Input__trailing-lane i {
          font-size: 16px;
        }

.ResponsibleGamingContainer.OffCanvas .canvas-content .canvas-header + div {
      text-align: center;
    }

.ResponsibleGamingWidget__timespan-limit-selector .button {
        padding: 0;
        background: #00a19a;
        box-shadow: none;
      }

.ResponsibleGamingWidget__timespan-limit-selector .button:hover {
          background: #1c635f;
        }

.ResponsibleGamingWidget__timespan-limit-selector .tg-input {
        margin: 0;
      }

.ResponsibleGamingWidget__timespan-limit-selector .tg-input .Input input {
          width: 100%;
        }

.ResponsibleGamingWidget__timespan-box {
      padding: 20px 8px;
    }

.ResponsibleGamingWidget__timespan-title,
    .ResponsibleGamingWidget__timespan-row,
    .ResponsibleGamingWidget__timespan-limit-selector {
      margin: 8px;
    }

.PayNPlay .SetLimitsStep__summary-item,
    .PayNPlay .SetLimitsStep__notice {
      color: #ffffff;
      padding: 0.25em;
    }

.PayNPlay .SetLimitsStep__actions {
      background-color: #242424;
    }

.PayNPlay .SetLimitsStep__actions .button {
        max-width: 60%;
        margin: 10px auto;
      }

.messages-list .messages-list-item {
    background: #242424;
    padding: 8px 15px;
  }

.messages-list .messages-list-item .header-row .icon {
        display: none;
      }

.messages-list .messages-list-item .header-row .title {
        margin: 5px 0;
      }

.messages-list .messages-list-item .header-row .time {
        margin: 0;
        padding: 0;
      }

.messages-list .messages-list-item:not(.open).messages-list-item:hover {
      background: #242424;
    }

.messages-list .messages-list-item .message-wrapper {
      padding: 10px 0;
    }

.messages-list .messages-list-item .message-wrapper:before {
        display: none;
      }

.notification {
  background: #1b1b1b;
}

.notification .notification-button {
    border-top: 1px solid #154341;
  }

.notification .icon {
      display: none;
  }

.PayNPlay {
  --pnp-spacing: 10px;
  --pnp-width: 720px;
}

.PayNPlay .disclaimer,
  .PayNPlay .question {
    font-style: italic;
  }

.PayNPlay .question {
    margin: 0;
  }

.PayNPlay.limits,
  .PayNPlay.contact {
    --pnp-width: 500px;
  }

.PayNPlay.limits .tg-select .Select select, .PayNPlay.contact .tg-select .Select select {
          text-align: left;
          -webkit-transform: skew(0deg);
                  transform: skew(0deg);
        }

.PayNPlay.limits .tg-select .Select__status-line, .PayNPlay.contact .tg-select .Select__status-line {
          display: none;
        }

.PayNPlay.limits .Limits__skip-prompt button + button {
          margin-left: 10px;
        }

.PayNPlay.consent .Consent__buttons {
        justify-content: center;
        grid-gap: 10px;
        gap: 10px;
      }

.PayNPlay.bonus .Bonus .BonusSelector {
        display: flex;
        justify-content: center;
      }

.PayNPlay.bonus .Bonus .BonusSelector__bonus {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: #282828;
          margin: 20px 0;
          max-width: 500px;
          padding-bottom: 20px;
        }

.PayNPlay.bonus .Bonus .BonusSelector__bonus h3 {
            font-size: 2em;
          }

.PayNPlay.bonus .Bonus .BonusSelector__bonus a {
            order: 2;
          }

.PayNPlay.bonus .Bonus .BonusSelector__bonus button {
            margin-block: var(--pnp-spacing);
          }

.PayNPlay.bonus .Bonus .BonusSelector__bonus__content {
            padding: 0px 20px;
            text-align: left;
          }

.PayNPlay.bonus .Bonus .BonusSelector__bonus__image img {
              max-width: unset;
            }

.PayNPlay.bonus button {
      color: #ffffff;
    }

.PayNPlay .tg-input {
    min-width: 230px;
  }

.PayNPlay .tg-input .Input {
      width: 100%;
    }

.PayNPlay .tg-input .Input__trailing-lane {
        display: none;
      }

.PayNPlay .tg-input .Input__status-line {
        display: none;
      }

.PayNPlay .tg-input .Input--status-failure {
        caret-color: auto;
      }

.PayNPlay .Amount__tac,
    .PayNPlay .Amount__bonus-code-button,
    .PayNPlay .Amount__question {
      font-style: italic;
    }

.PayNPlay .Amount__tac {
      margin-bottom: 0 !important;
    }

.PayNPlay .Amount__question .button {
        -webkit-transform: none;
        transform: none;
        background: none;
        font-style: italic;
        padding: 0;
        font-weight: 400;
        text-transform: none;
        font-size: inherit;
        text-decoration: underline;
      }

.PayNPlay .Amount form {
    display: initial;
  }

.PayNPlay .Amount form .Amount__quick-amounts {
      margin: var(--pnp-spacing) auto;
    }

.PayNPlay .Amount form .Amount__custom-amount,
    .PayNPlay .Amount form .Amount__button {
      display: inline-flex;
    }

.PayNPlay .Amount form .Amount__custom-amount {
      margin-right: var(--pnp-spacing);
    }

.PayNPlay .Amount form .Amount__custom-amount .tg-input__status-text {
        color: #d63e3e;
        font-weight: 700;
        margin: 0 0 5px;
      }

.PayNPlay .Amount form button[disabled] {
      opacity: 0.2;
    }

.PayNPlay .Amount form .Amount__bonus-code-input {
      display: inline-flex;
      margin-right: var(--pnp-spacing);
      margin-top: 0;
    }

.PayNPlay .Amount form .Amount__bonus-code-button {
      margin-top: 0;
      margin-bottom: var(--pnp-spacing);
    }

.PayNPlay .Amount form .deposit-confirmation {
      width: 100%;
      order: 1;
      text-align: left;
      margin: 10px 0;
    }

.PayNPlay .Amount form .deposit-confirmation .flex {
        max-width: 500px;
        margin: 0 auto;
        border: 1px solid #fff;
        color: #fff;
        padding: 20px;
        align-items: flex-start;
      }

.PayNPlay .Amount form .deposit-confirmation .flex input {
          flex: 1 0 auto;
        }

.PayNPlay header {
    color: #ffffff;
  }

.PayNPlay header h1,
    .PayNPlay header h2,
    .PayNPlay header h3,
    .PayNPlay header h4,
    .PayNPlay header h5,
    .PayNPlay header h6 {
      font-size: 2em;
      margin: 0 0 10px 0;
    }

.PayNPlay .ExternalAuth {
    height: auto;
  }

.PayNPlay .LowLimitStep,
  .PayNPlay .LowLimitStep .link {
    color: #ffffff;
  }

.PayNPlay form.DetailsStep {
    flex-direction: column;
  }

.PayNPlay form.DetailsStep .communication-consent {
      width: auto;
    }

.PayNPlay form.DetailsStep .communication-consent .flex {
        justify-content: center;
      }

.PayNPlay form.DetailsStep .communication-consent label {
        width: auto;
      }

@media (max-width: 540px) {
    .PayNPlay .Amount form {
      display: flex;
      flex-flow: wrap;
      align-items: baseline;
    }

      .PayNPlay .Amount form .tg-input {
        min-width: 0;
        width: 100%;
      }

      .PayNPlay .Amount form .Amount__bonus-code-button {
        margin-top: var(--pnp-spacing);
        width: 100%;
      }

      .PayNPlay .Amount form .Amount__bonus-code-input {
        margin-bottom: var(--pnp-spacing);
      }

      .PayNPlay .Amount form .Amount__quick-amounts {
        flex-direction: column;
        margin: 0;
        width: 100%;
      }
        .PayNPlay .Amount form .Amount__quick-amounts button {
          margin: 5px;
        }
        .PayNPlay .Amount form .Amount__quick-amounts button:first-child {
          margin-left: 5px;
        }
        .PayNPlay .Amount form .Amount__quick-amounts button:last-child {
          margin-right: 5px;
        }

      .PayNPlay .Amount form .Amount__custom-amount,
      .PayNPlay .Amount form .button[type='submit'] {
        margin: 5px 5px 0 5px;
        width: 100%;
      }
  }

.Popup .Popup__content {
    border-radius: 10px;
    color: #00a19a;
  }

.Popup .Popup__content select option {
        background: none;
      }

.Popup .Popup__header {
    color: #ffffff;
  }

.Popup .Popup__children .RealityCheckContainer .actions .button:last-child {
            width: 100%;
            margin: 15px 0;
            box-shadow: inset 0 0 0 2px #00a19a;
            color: #00a19a;
          }

@media (max-width: 993px) {
    .Popup .Popup__children {
      font-size: 14px;
    }
  }

.LimitConfirmation {
  background: #ffffff;
  color: #00a19a;
  border-radius: 10px;
  padding: 20px;
  border: 0;
  box-shadow: 3px 2px 8px 0 rgba(0, 0, 0, 0.4);
}

.SuccessfulDepositContainer img {
    width: 15%;
  }

.PromotionDetailContainer .promotion-banner {
    position: relative;
  }

.PromotionDetailContainer .promotion-banner .banner-image:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(
    rgba(27, 27, 27, 1) 0%,
    transparent 20%,
    transparent 85%,
    rgba(27, 27, 27, 1) 100%
  );
        z-index: 1;
        pointer-events: none;
      }

.PromotionDetailContainer .promotion-banner .banner-content {
      position: absolute;
      display: inherit;
      background: none;
      padding: 50px;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      width: 80%;
    }

.PromotionDetailContainer .promotion-banner .banner-content .button.primary {
        font-size: 1.3em;
      }

.PromotionDetailContainer .promotion-content {
    padding: 50px;
    max-width: 800px;
  }

.PromotionDetailContainer .promotion-content .content {
      font-size: 1.3em;
    }

.PromotionsContainer .promotions-grid .Promotion {
      background: #282828;
    }

.PromotionsContainer .promotions-grid .Promotion .promotion-content-wrapper .title {
          display: none;
        }

.PromotionsContainer .promotions-grid .Promotion .promotion-content-wrapper .summary {
          font-size: 13px;
          margin: 0 0 10px 0;
        }

.PromotionsContainer .promotions-grid .Promotion .promotion-content-wrapper .summary > *:first-child {
              margin-top: 0;
            }

@media (max-width: 992px) {
    .is-offcanvas-open .IndexContainerPnp .rg-wrapper, .CasinoContainer .rg-wrapper {
      background: #1b1b1b;
    }
  }

.PlayContainer .rg-wrapper {
    background: #1b1b1b;
  }

.rg-wrapper {
  pointer-events: none;
}

.rg-wrapper .responsible-gaming-logos {
    pointer-events: auto;
  }

body .SessionSummary .Summary .col .info span:nth-child(2) {
          color: #00a19a;
        }

body .SessionSummary .Summary .col .date {
        color: #00a19a;
      }

body .SessionSummary .deposit-limit .limit div:nth-child(2) {
              color: #00a19a;
          }

@media (max-width: 992px) {
    .jurisdiction-sga .OffCanvas {
      height: calc(100% - 30px);
    }
  }

@media (max-width: 992px) {

.jurisdiction-sga.is-authenticated .OffCanvas {
      top: 0;
      height: 100%
  }
    }

.jurisdiction-sga.is-authenticated .OffCanvas .canvas-content {
      padding-top: calc(30px + 20px);
    }

@media (max-width: 992px) {

.OffCanvas {
   height: 100%
}
  }

.OffCanvas .canvas-content header {
      text-align: center;
      padding: 0;
    }

.OffCanvas .canvas-content header > div > *:first-child, .OffCanvas .canvas-content header > *:first-child {
          margin: 0;
        }

.OffCanvas .canvas-content .canvas-header {
      min-height: 32px;
    }

.OffCanvas .canvas-content .canvas-header .canvas-back {
        height: 32px;
        width: 32px; 
      }

@media (max-width: 992px) {
        .OffCanvas .canvas-content .canvas-header .canvas-close {
          height: 22px;
          width: 22px;
        }
  }

body:not(.hide-sticky-footer) .canvas-content {
  padding-bottom: calc(55px + 15px);
}

.StickyFooter-mobile {
  top: 60px;
  z-index: 0;
  width: 100%;
  border-radius: 0;
  border: 1px solid hsla(0, 0%, 100%, 0.3);
}

.StickyFooter-mobile.hide {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }

.StickyFooter-mobile .stickyfooter-nav-items a {
      font-weight: bold;
      display: flex;
      flex: 1 1 auto;
      text-align: center;
    }

.StickyFooter-mobile .stickyfooter-nav-items a .text {
        width: 100%;
        border-right: 1px solid hsla(0, 0%, 100%, 0.3);
      }

.StickyFooter-mobile .stickyfooter-nav-items a .icon {
        display: none;
      }

.StickyFooter-mobile .stickyfooter-nav-items a.hamburger-icon,
      .StickyFooter-mobile .stickyfooter-nav-items a.exit-sidebar-icon {
        flex: none;
        width: 70px;
      }

.StickyFooter-mobile .stickyfooter-nav-items a.hamburger-icon div, .StickyFooter-mobile .stickyfooter-nav-items a.exit-sidebar-icon div {
          display: none;
        }

.StickyFooter-mobile .stickyfooter-nav-items a.hamburger-icon {
        position: initial;
      }

.StickyFooter-mobile .stickyfooter-nav-items a.hamburger-icon img {
          width: 35px;
        }

.StickyFooter-mobile .stickyfooter-nav-items a.exit-sidebar-icon img {
          width: 18px;
        }

.StickyFooter-mobile .notifications-badge--right[data-notifications]:not(
      [data-notifications='']
    ):not([data-notifications='0']):after {
    color: #ffffff;
  }

.jurisdiction-sga .StickyFooter-mobile {
  top: calc(60px + 30px);
}

.BetslipTab {
  display: none;
}

@media (max-width: 992px) {
  .BetslipTab {
    display: flex;
    bottom: 0px;
    height: 55px;
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 4px;
    padding: 0px 20px;
    background-color: #1c635f;
    font-weight: bold;
  }

    .BetslipTab.active {
      bottom: 70px;
    }
      @supports (bottom: env(safe-area-inset-bottom)) {

    .BetslipTab.active {
        bottom: calc(70px + env(safe-area-inset-bottom, 10px))
    }
      }

      .BetslipTab.active.hide {
        transition: 0.2s all;
        bottom: 10px;
      }
        @supports (bottom: env(safe-area-inset-bottom)) {

      .BetslipTab.active.hide {
          bottom: calc(10px + env(safe-area-inset-bottom, 10px))
      }
        }
}

.SubscriptionsContainer .newsletter-container .newsletter-buttons {
      grid-template-columns: 1fr;
    }

.TournamentDetailContainer .tournament-banner {
    position: relative;
  }

.TournamentDetailContainer .tournament-banner .banner-image:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(
    rgba(27, 27, 27, 1) 0%,
    transparent 20%,
    transparent 85%,
    rgba(27, 27, 27, 1) 100%
  );

        z-index: 1;
        pointer-events: none;
      }

.TournamentDetailContainer .tournament-banner .banner-content {
      position: absolute;
      display: inherit;
      background: none;
      padding: 50px;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      width: 80%;
    }

.TournamentDetailContainer .tournament-content {
    padding: 50px;
  }

.TournamentDetailContainer .tournament-content .leaderboard-header {
      background-color: #1c635f;
      padding: 20px;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      font-weight: bold;
    }

.TournamentDetailContainer .tournament-content .leaderboard {
      border-radius: 10px;
    }

.TournamentDetailContainer .tournament-content .leaderboard-header + .leaderboard {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }

.TournamentDetailContainer .tournament-content .content {
      font-size: 1.3em;
    }

.TournamentsContainer .tournaments-grid .Tournament {
      background: #282828;
    }

.TournamentsContainer .tournaments-grid .Tournament .tournament-content-wrapper .title {
          font-size: 15px;
          margin: 0;
          color: #00a19a;
        }

.TournamentsContainer .tournaments-grid .Tournament .tournament-content-wrapper .summary {
          font-size: 13px;
          margin: 10px 0;
        }

.SourceOfWealthContainer textarea {
    font-size: 14px;
  }

.SourceOfWealthContainer hr {
    border: none;
    height: 1px;
    background: #00a19a;
  }

.SourceOfWealthContainer .occupation .alternatives select, 
      .SourceOfWealthContainer .occupation .alternatives input {
        color: #00a19a;
      }

.SourceOfWealthContainer .completed {
    color: #00a19a; 
  }

.SourceOfWealthContainer .button-wrapper .tertiary {
      border: 1px solid #000000;
      color: #000000;
    }

.SourceOfWealthContainer .button-wrapper .tertiary:hover {
        background: none;
      }

@media (max-width: 992px) {
    .SourceOfWealthContainer .button-wrapper {
      flex-direction: column;
    }

      .SourceOfWealthContainer .button-wrapper .button {
        width: 100%;
        margin: 0;
      }

        .SourceOfWealthContainer .button-wrapper .button:nth-child(2) {
          margin-top: 10px;
        }
  }

.WinLossContainer {
  flex-wrap: wrap;
}

@media (max-width: 540px) {
      .WinLossContainer .win-loss-section .win-loss-header {
        font-size: 10px;
      }

      .WinLossContainer .win-loss-section .win-loss-amount {
        font-size: 14px;
      }
  }

.HistoryContainer .WinLossContainer .win-loss-section {
      text-align: center;
    }

.Alert.level-success {
    border-color: #1c8906;
    background: rgba(52, 208, 88, 0.15);
  }

.Alert.level-info {
    border-color: #facf0c;
    background: rgba(250, 207, 12, 0.15);
  }

.Alert.level-warning {
    border-color: #facf0c;
    background: rgba(250, 207, 12, 0.15);
  }

.Alert.level-danger {
    border-color: #f9473e;
    background: rgba(249, 71, 62, 0.15);
  }

body {
  --gameplay-gamedetails-bgcolor: #00a19a;

  --gameplay-friendly-sign-in-message-height: 0px;
}

.PlayContainer .Footer {
  padding-bottom: 60px;
  margin-bottom: 0;
}

.PlayContainer .button.icon {
    background: none;
    color: #ffffff;
  }

.PlayContainer .GamePlay {
    background: #323232;
  }

@media (max-width: 992px) {
      .PlayContainer .GamePlay .PopUnder {
        top: 0;
      }
          .PlayContainer .GamePlay .PopUnder--closed .PopUnder__content {
            -webkit-transform: translateY(-100%);
                    transform: translateY(-100%);
          }
        .PlayContainer .GamePlay .PopUnder__content {
          top: calc(
            58px + var(--gameplay-topbar-height) +
              var(--gameplay-friendly-sign-in-message-height)
          );
        }
    }

.PlayContainer .GamePlayHeader {
    padding: 5px 10px;
    background: #1b1b1b;
  }

.PlayContainer .GamePlayHeader__quick-deposit .custom-amount {
        overflow: initial;
      }

.PlayContainer .GamePlayHeader .MembershipActions .button.primary {
        background: rgb(255, 255, 255);
        color: rgb(0, 161, 154);
      }

.PlayContainer .GamePlayHeader .MembershipActions .button.primary span {
          font-weight: 800;
          font-size: 1.5em;
          padding: 0.3em;
        }

.PlayContainer .GameDetails {
    padding: 5px 10px;
  }

@media (max-width: 992px) {

.PlayContainer .GameDetails .GameDetails__categories-nav {
        display: block
    }
      }

.PlayContainer .GameDetails .GameDetails__categories-nav .casino-search.active {
          flex-direction: row;
          justify-content: inherit;
        }

.PlayContainer .GameDetails .GameDetails__categories-nav .casino-search .search-input {
          display: flex;
          align-items: center;
        }

.PlayContainer .GameDetails .GameDetails__categories-nav .casino-search .search-input .Input {
            margin: 0 20px;
            -webkit-transform: skew(-15deg);
                    transform: skew(-15deg);
            background: #ffffff;
          }

.PlayContainer .GameDetails .GameDetails__categories-nav .casino-search .search-input .Input__status-line {
              background: #ffffff;
            }

.PlayContainer .GameDetails .GameDetails__categories-nav input {
        background: none;
        -webkit-transform: skew(15deg);
                transform: skew(15deg);
      }

.PlayContainer .GameDetails .GameDetails__categories-nav i {
        font-size: 20px;
      }

.PlayContainer .GameDetails .GameDetails__category .last-slide a {
        background: #1c635f;
      }

@media (max-width: 1024px) {
    .PlayContainer .GamePlayHeader {
      height: 58px;
    }
          .PlayContainer .GamePlayHeader__quick-deposit .QuickDepositMobile i {
            font-size: 25px;
          }
        .PlayContainer .GamePlayHeader__hamburger img {
          height: 18px;
        }
    .PlayContainer .GameDetails {
      background: #1b1b1b;
    }
        .PlayContainer .GameDetails__actions .button {
          background: linear-gradient(
    45deg,
    rgba(0, 161, 154, 1) 13%,
    rgba(0, 161, 154, 1) 32%,
    rgba(0, 161, 154, 1) 61%,
    rgba(75, 163, 141, 1) 96%
  );
          box-shadow: none;
          margin: 5px;
        }
        .PlayContainer .GameDetails__category .NoGamesMessage {
          background: #1b1b1b;
        }
        .PlayContainer .GameDetails__categories-nav .casino-search .icon {
          background: none;
        }
  }

.QuickDepositContainer:not(.Popup) {
  max-height: 100vh;
}

.WithdrawContainer .cashier-accordion .payment-method-selector .payment-method-item-content .account-selection {
          background: none;
        }

.WithdrawContainer .cashier-accordion .payment-method-selector .payment-method-item-content .custom-amount-and-submit .custom-amount {
            margin-right: 5px;
          }

.WithdrawContainer .cashier-accordion .payment-method-selector .payment-method-item-content .custom-amount-and-submit .custom-amount input {
              background: none;
            }

.WithdrawContainer .cashier-accordion .payment-method-selector .payment-method-item-content .custom-amount-and-submit button {
            border-radius: 5px;
          }

@media (min-width: 1025px) {
        .PageBuilder .category-row.category-row .slider .list .track .items .slide-item {
          min-width: calc(calc(100vw / 16) - 6.25px);
          height: 54px;
        }
          @media (max-width: 2400px) {
        .PageBuilder .category-row.category-row .slider .list .track .items .slide-item {
            min-width: calc(calc(100vw / 12) - 8.3px)
        }
          }
          @media (max-width: 1600px) {
        .PageBuilder .category-row.category-row .slider .list .track .items .slide-item {
            min-width: calc(calc(100vw / 10) - 10px)
        }
          }

          @media (max-width: 1200px) {
        .PageBuilder .category-row.category-row .slider .list .track .items .slide-item {
            min-width: calc(calc(100vw / 8) - 12.5px)
        }
          }
  }

@media (max-width: 1025px) {
      .PageBuilder .category-row .slider .list .track .items .slide-item {
        min-width: 40vw;
        height: 80px;
        /* This is so the scroll bar wont be placed on top of the title */
        margin-bottom: 3px;
      }
        .PageBuilder .category-row .slider .list .track .items .slide-item:last-child {
          margin-right: 10px;
        }
        @media (min-width: 700px) {
      .PageBuilder .category-row .slider .list .track .items .slide-item {
          min-width: 20vw
      }
        }
        @media (min-width: 992px) {
      .PageBuilder .category-row .slider .list .track .items .slide-item {
          min-width: 12.5vw;
          width: 12.5vw
      }
        }
        .PageBuilder .category-row .slider .list .track .items .slide-item .CasinoCategoryRow {
          width: 100%;
        }
          .PageBuilder .category-row .slider .list .track .items .slide-item .CasinoCategoryRow a {
            display: flex;
            flex-flow: column;
            height: 100%;
            text-decoration: none;
          }
            .PageBuilder .category-row .slider .list .track .items .slide-item .CasinoCategoryRow a .category-name {
              position: inherit;
            }
              .PageBuilder .category-row .slider .list .track .items .slide-item .CasinoCategoryRow a .category-name .title {
                font-size: 12px;
              }
  }

.CasinoCategoryRow {
  height: 100%;
  width: 100%;
  text-align: center;
  position: relative;
}

.CasinoCategoryRow a .thumbnail {
      background: #003835;
      border-radius: 6px;
    }

.CasinoCategoryRow a .thumbnail .placeholder-thumbnail {
        display: none;
      }

.CasinoCategoryRow a .thumbnail .category-icon,
      .CasinoCategoryRow a .thumbnail .category-thumbnail {
        transition: opacity 0.2s ease-in-out;
        opacity: 0.5;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        padding: 8px;
        z-index: 2;
        object-fit: contain;
      }

.CasinoCategoryRow a .thumbnail .category-icon img, .CasinoCategoryRow a .thumbnail .category-thumbnail img {
          max-height: 100%;
          max-width: 100%;
        }

.CasinoCategoryRow a .thumbnail:hover .category-icon,
        .CasinoCategoryRow a .thumbnail:hover .category-thumbnail {
          opacity: 1;
        }

.CasinoCategoryRow a .category-name {
      display: none;
      position: absolute;
      pointer-events: none;
      width: 100%;
      top: auto;
      left: auto;
      -webkit-transform: none;
              transform: none;
    }

.CasinoCategoryRow a .category-name .title {
        font-size: 14px;
        font-weight: normal;
      }

.CasinoCategoryRow a .overlay {
      display: none;
    }

@media (max-width: 992px) {

.SportsContainer .SpringBuilder {
      height: calc(
        var(--inner-height) - 60px -
          55px
      )
  }
    }

@media (max-width: 992px) {

.jurisdiction-sga .SportsContainer .SpringBuilder {
    height: calc(
      var(--inner-height) - 60px - 30px -
        55px
    )
}
  }

@media (max-width: 992px) {

body.jurisdiction-sga .landing-page .landing-banner {
    min-height: calc(100svh - 145px)
}
  }

.landing-page .content-wrapper {
    padding: 0;
  }

.landing-page .content-wrapper .actual-content {
      display: none;
    }

@media (max-width: 1200px) {

.landing-page .content-wrapper {
      margin-right: 0
  }
    }

@media (max-width: 992px) {

.landing-page .content-wrapper {
      margin-left: 0
  }
    }

.landing-page .PageBuilder > * {
    margin-bottom: 20px;
  }

.landing-page main.container .content-container {
    max-width: none;
    margin: 0;
    padding: 0;
  }

.landing-page main.container .content-container .content {
      display: block;
    }

.landing-page .NavList {
    display: none;
  }

.landing-page .landing-banner {
    min-height: 75vh;
    max-height: 75vh;
    background-position: center;
    width: 100%;
    position: relative;
    top: 0;
  }

@media (max-width: 992px) {

.landing-page .landing-banner {
      min-height: calc(100svh - 115px)
  }
    }

.landing-page .landing-banner .container {
      position: absolute;
      left: 50%;
      z-index: 10;
      -webkit-transform: translate3d(-50%, 0, 0);
              transform: translate3d(-50%, 0, 0);
      bottom: 4vh;
      text-align: center;
    }

@media (max-width: 992px) {

.landing-page .landing-banner .container {
        width: 90%
    }
      }

.landing-page .landing-banner .container h1,
      .landing-page .landing-banner .container h2,
      .landing-page .landing-banner .container h3 {
        color: #fff;
        font-size: calc(1em + 1.5vw);
        font-weight: 700;
        line-height: 1em;
        margin: 0;
        max-width: 730px;
        text-transform: uppercase;
      }

@media (max-width: 992px) {

.landing-page .landing-banner .container h1,
      .landing-page .landing-banner .container h2,
      .landing-page .landing-banner .container h3 {
          font-size: 2em
      }
        }

.landing-page .landing-banner .container p {
        color: #00a19a;
        font-size: 1.4em;
        line-height: 1em;
        font-weight: 700;
        text-transform: uppercase;
      }

.landing-page .landing-banner .container .button {
        margin: 25px 0;
        padding: 2px 35px;
        font-size: 18px;
      }

.landing-page .landing-banner::after {
      content: '';
      position: absolute;
      height: 80%;
      width: 100%;
      left: 0;
      bottom: 0;
      background: linear-gradient(to top, #000, transparent);
    }

.landing-page .widget-steps {
    padding: 0 50px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0 auto;
    max-width: 1000px;
    height: 25vh;
    align-items: center;
    justify-content: center;
  }

@media (max-width: 992px) {

.landing-page .widget-steps {
      display: flex;
      list-style-type: none;
      margin: 20px auto;
      padding: 25px 20px;
      align-items: center;
      justify-content: center;
      width: 100%;
      overflow: hidden
  }

      @media (orientation: landscape) {

.landing-page .widget-steps {
        height: initial
  }
      }
    }

@media (max-width: 600px) {

.landing-page .widget-steps {
      height: 15vh;
      align-items: normal
  }
    }

.landing-page .widget-steps .Section {
      text-align: center;
      padding: 0 20px;
    }

.landing-page .widget-steps .Section a {
        text-decoration: none;
      }

.landing-page .widget-steps .Section p {
        min-height: 40px;
      }

.landing-page .widget-steps .Section:hover img {
          -webkit-filter: brightness(1) invert(0);
                  filter: brightness(1) invert(0);
          transition: all 0.4s;
          -webkit-transform: translateY(-8px);
                  transform: translateY(-8px);
        }

.landing-page .widget-steps .Section img {
        max-height: 60px;
        -webkit-filter: brightness(0) invert(1);
                filter: brightness(0) invert(1);
        transition: all 0.2s;
        position: relative;
        top: 0;
      }

@media (max-width: 992px) {

.landing-page .widget-steps .Section img {
          max-height: 48px
      }
        }

@media (max-width: 992px) {

.landing-page .widget-steps .Section {
        width: 33.33%;
        padding: 0 20px;
        align-items: center;
        display: flex;
        flex-direction: column
    }

        .landing-page .widget-steps .Section p {
          margin: 0;
        }
            .landing-page .widget-steps .Section:last-child h2:after {
              display: none;
            }
      }

@media (max-width: 600px) {

.landing-page .widget-steps .Section {
        padding: 0 5px
    }

        .landing-page .widget-steps .Section p {
          display: none;
        }
      }

.landing-page .widget-steps .Section h2 {
        font-size: 4.5em;
        margin: 0;
      }

@media (max-width: 992px) {

.landing-page .widget-steps .Section h2 {
          font-size: 2.2em;
          text-align: center
      }
        }

.landing-page .widget-steps .Section h3 {
        font-size: 1.1em;
        text-transform: uppercase;
        color: #00a19a;
        margin-top: 5px;
      }

@media (max-width: 992px) {

.landing-page .widget-steps .Section h3 {
          font-size: 0.8em
      }
        }

@media (max-width: 600px) {

.landing-page .widget-steps .Section h3 {
          min-height: 30px
      }
        }

.landing-page .landing-terms {
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0 100px 0;
  }

@media (max-width: 992px) {

.landing-page .landing-terms {
      margin: 0 20px;
      padding: 0 15px
  }
    }

.landing-page .landing-terms .container {
      display: flex;
      flex-direction: column;
    }

.landing-page .landing-terms ol {
      padding: 0;
    }

.landing-page .landing-terms li {
      padding: 10px 0;
    }
