@font-face {
  font-family: IBM Plex sans;
  font-style: normal;
  font-weight: 300;
  src: local('IBM Plex sans light 300'), local('IBMPlexSans-Light'),
    url('/skin/fonts/IBMPlexSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: IBM Plex sans;
  src: url('/skin/fonts/IBMPlexSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: IBM Plex sans;
  font-style: normal;
  font-weight: 400;
  src: local('IBM Plex sans'), local('IBMPlexSans-Regular'),
    url('/skin/fonts/IBMPlexSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: IBM Plex sans;
  font-style: normal;
  font-weight: 500;
  src: local('IBM Plex medium'), local('IBMPlexSans-Medium'),
    url('/skin/fonts/IBMPlexSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: IBM Plex sans;
  font-style: normal;
  font-weight: 600;
  src: url('/skin/fonts/IBMPlexSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: IBM Plex sans;
  src: local('IBM Plex sans bold'), local('IBMPlexSans-Bold'),
    url('/skin/fonts/IBMPlexSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: IBM Plex sans;
  src: local('IBM Plex sans bold'), local('IBMPlexSans-Bold'),
    url('/skin/fonts/IBMPlexSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
