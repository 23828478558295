.WithdrawContainer {
  .cashier-accordion {
    .payment-method-selector {
      .payment-method-item-content {
        .account-selection {
          background: none;
        }
        .custom-amount-and-submit {
          .custom-amount {
            margin-right: 5px;
            input {
              background: none;
            }
          }
          button {
            border-radius: 5px;
          }
        }
      }
    } 
  }
}