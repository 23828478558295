.Footer {
  background-color: #00a19a;
  color: #ffffff;
}

.Footer .topSection {
    background-color: #1c635f;
  }

.Footer .topSection .container {
      padding: 10px 15vw;
      min-height: 50px;
      font-size: 9px;
      font-weight: 600;
      position: relative;
      text-transform: uppercase;
      margin-bottom: 40px;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
    }

.Footer .topSection .container .LanguageSelector {
        bottom: calc(50% - 13.5px);
      }

.Footer .topSection .container nav {
        line-height: 30px;
        flex-wrap: wrap;
        display: flex;
        justify-content: center;
      }

.Footer .topSection .container nav .navigation {
          white-space: nowrap;
          font-size: 12px;
          margin: 0 20px;
          text-decoration: none;
        }

.Footer .topSection .container nav .navigation:hover {
            text-decoration: underline;
          }

/* Content / Bottom section */

.Footer .content-container {
    display: block;
    padding: 0 15vw;
    width: 100%;
    text-align: center;
    max-width: 100%;
  }

.Footer .content-container .icons {
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 20px;
      justify-content: center;
    }

.Footer .content-container .icons img {
      -webkit-filter: saturate(0);
              filter: saturate(0);
      height: 20px;
      opacity: 0.5;
      margin: 0 10px;
      margin-bottom: 20px;
    }

.Footer .content-container .icons img:hover {
        -webkit-filter: saturate(1);
                filter: saturate(1);
        opacity: 1;
      }

.Footer .content-container .section {
      padding-bottom: 40px;
    }

.Footer .content-container .section h3 {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 20px;
        margin-top: 0;
      }

.Footer .content-container .section h3 a {
          text-decoration: none;
        }

.Footer .content-container .section h3 a:hover {
            color: #00a19a;
          }

.Footer .content-container .section.one .footer-logo img {
            margin-bottom: 24px;
            height: 32px;
          }

.Footer .content-container .section.one .trademark {
          margin-bottom: 24px;
          font-size: 12px;
        }

.Footer .content-container .section.one .licence,
        .Footer .content-container .section.one .social {
          margin-bottom: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          -webkit-filter: saturate(0%);
                  filter: saturate(0%);
        }

.Footer .content-container .section.one .licence:hover, .Footer .content-container .section.one .social:hover {
            -webkit-filter: saturate(100%);
                    filter: saturate(100%);
          }

.Footer .content-container .section.one .social {
          -webkit-filter: initial;
                  filter: initial;
          flex-wrap: wrap;
          grid-gap: 1em;
          gap: 1em;
        }

.Footer .content-container .section.one .rights-reserved-content {
          font-size: 12px;
        }

.Footer .content-container .section.one .licence img,
        .Footer .content-container .section.one .social img {
          height: 44px;
          margin-right: 10px;
          display: block;
        }

.Footer .content-container .section.one .social img {
          height: 30px;
          margin-right: 0;
        }

@media (max-width: 992px) {
      .Footer .topSection .container {
        padding: 10px 15px;
      }

    .Footer .content-container {
      padding: 0 15px;
    }
}
