.StickyFooter-mobile {
  top: var(--header-height-mobile);
  z-index: 0;
  width: 100%;
  border-radius: 0;
  border: 1px solid hsla(0, 0%, 100%, 0.3);

  &.hide {
    transform: translateY(-100%);
  }

  .stickyfooter-nav-items {
    a {
      font-weight: bold;
      display: flex;
      flex: 1 1 auto;
      text-align: center;

      .text {
        width: 100%;
        border-right: 1px solid hsla(0, 0%, 100%, 0.3);
      }

      .icon {
        display: none;
      }

      &.hamburger-icon,
      &.exit-sidebar-icon {
        flex: none;
        width: 70px;

        div {
          display: none;
        }
      }

      &.hamburger-icon {
        position: initial;
        img {
          width: 35px;
        }
      }

      &.exit-sidebar-icon {
        img {
          width: 18px;
        }
      }
    }
  }

  .notifications-badge--right[data-notifications]:not(
      [data-notifications='']
    ):not([data-notifications='0']):after {
    color: var(--white);
  }
}

.jurisdiction-sga .StickyFooter-mobile {
  top: calc(var(--header-height-mobile) + var(--rg-bar-height));
}

.BetslipTab {
  display: none;
}

@media (max-width: 992px) {
  .BetslipTab {
    display: flex;
    bottom: 0px;
    height: 55px;
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 4px;
    padding: 0px 20px;
    background-color: #1c635f;
    font-weight: bold;

    &.active {
      bottom: 70px;
      @supports (bottom: env(safe-area-inset-bottom)) {
        bottom: calc(70px + env(safe-area-inset-bottom, 10px));
      }

      &.hide {
        transition: 0.2s all;
        bottom: 10px;
        @supports (bottom: env(safe-area-inset-bottom)) {
          bottom: calc(10px + env(safe-area-inset-bottom, 10px));
        }
      }
    }
  }
}
