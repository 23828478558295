.game-history-wrapper {
  .dim-btn.on {
    svg {
      fill: var(--primary-color);
    }
  }

  .home-button {
    border: 2px solid var(--primary-color);
    border-radius: 25px;
  }
}
