.IndexContainerPnp .index__header {
  .index__header__logo {
    width: 140px;
    display: block;
  }

  .index__header__actions {
    .index__header__actions__menu,
    .index__header__actions__home {
      span {
        display: none;
      }
      img,
      i {
        border-radius: 50%;
        border: 2px solid var(--white);
        height: 36px;
        width: 36px;
        padding: 8px;
      }

      i {
        position: relative;
        line-height: 36px;
        font-size: 1.3em;
      }
    }
  }
  @media (max-width: 992px) {
    position: absolute;
    &__actions {
      position: fixed;
      right: 10px;
    }
  }
}
