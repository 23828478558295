.TournamentDetailContainer {
  .tournament-banner {
    position: relative;

    .banner-image {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: var(--banner-fade);

        z-index: 1;
        pointer-events: none;
      }
    }

    .banner-content {
      position: absolute;
      display: inherit;
      background: none;
      padding: 50px;
      top: 50%;
      transform: translateY(-50%);
      width: 80%;
    }
  }

  .tournament-content {
    padding: 50px;

    .leaderboard-header {
      background-color: var(--secondary-color);
      padding: 20px;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      font-weight: bold;
    }

    .leaderboard {
      border-radius: 10px;
    }

    .leaderboard-header + .leaderboard {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }

    .content {
      font-size: 1.3em;
    }
  }
}
