.jurisdiction-sga {
  @media (max-width: 992px) {
    .OffCanvas {
      height: calc(100% - 30px);
    }
  }
}

.jurisdiction-sga.is-authenticated {
  .OffCanvas {
    @media (max-width: 992px) {
      top: 0;
      height: 100%;
    }

    .canvas-content {
      padding-top: calc(var(--rg-bar-height) + 20px);
    }
  }
}

.OffCanvas {
  @media (max-width: 992px) {
   height: 100%;
  }
  .canvas-content {
    header {
      text-align: center;
      padding: 0;

      > div > *,
      > * {
        &:first-child {
          margin: 0;
        }
      }
    }

    .canvas-header {
      min-height: 32px;
      .canvas-back {
        height: 32px;
        width: 32px; 
      }
    }
  }
  @media (max-width: 992px) {
    .canvas-content {
      .canvas-header {
        .canvas-close {
          height: 22px;
          width: 22px;
        }
      }
    }
  }
}
body:not(.hide-sticky-footer) .canvas-content {
  padding-bottom: calc(var(--sticky-footer-height) + 15px);
}