.is-offcanvas-open .IndexContainerPnp,
.CasinoContainer {
  @media (max-width: 992px) {
    .rg-wrapper {
      background: var(--dark-gray);
    }
  }
}

.PlayContainer {
  .rg-wrapper {
    background: var(--gameplay-header-bgcolor);
  }
}

.rg-wrapper {
  pointer-events: none;
  .responsible-gaming-logos {
    pointer-events: auto;
  }
}
