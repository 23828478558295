.PromotionsContainer {
  .promotions-grid {
    .Promotion {
      background: #282828;

      .promotion-content-wrapper {
        .title {
          display: none;
        }

        .summary {
          font-size: 13px;
          margin: 0 0 10px 0;

          > * {
            &:first-child {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}
