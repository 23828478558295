.WinLossContainer {
  flex-wrap: wrap;

  @media (max-width: 540px) {
    .win-loss-section {
      .win-loss-header {
        font-size: 10px;
      }

      .win-loss-amount {
        font-size: 14px;
      }
    }
  }
}
