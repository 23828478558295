.PayNPlay {
  --pnp-spacing: 10px;
  --pnp-width: 720px;

  .disclaimer,
  .question {
    font-style: italic;
  }

  .question {
    margin: 0;
  }

  &.limits,
  &.contact {
    --pnp-width: 500px;

    .tg-select {
      .Select {
        select {
          text-align: left;
          transform: skew(0deg);
        }
        &__status-line {
          display: none;
        }
      }
    }
  }

  &.limits {
    .Limits {
      &__skip-prompt {
        button + button {
          margin-left: 10px;
        }
      }
    }
  }

  &.consent {
    .Consent {
      &__buttons {
        justify-content: center;
        gap: 10px;
      }
    }
  }

  &.bonus {
    .Bonus {
      .BonusSelector {
        display: flex;
        justify-content: center;
        &__bonus {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: #282828;
          margin: 20px 0;
          max-width: 500px;
          padding-bottom: 20px;

          h3 {
            font-size: 2em;
          }
          a {
            order: 2;
          }
          button {
            margin-block: var(--pnp-spacing);
          }

          &__content {
            padding: 0px 20px;
            text-align: left;
          }

          &__image {
            img {
              max-width: unset;
            }
          }
        }
      }
    }
    button {
      color: var(--white);
    }
  }

  .tg-input {
    min-width: 230px;
    .Input {
      width: 100%;
      &__trailing-lane {
        display: none;
      }
      &__status-line {
        display: none;
      }
      &--status-failure {
        caret-color: auto;
      }
    }
  }

  .Amount {
    &__tac,
    &__bonus-code-button,
    &__question {
      font-style: italic;
    }

    &__tac {
      margin-bottom: 0 !important;
    }

    &__question {
      .button {
        -webkit-transform: none;
        transform: none;
        background: none;
        font-style: italic;
        padding: 0;
        font-weight: 400;
        text-transform: none;
        font-size: inherit;
        text-decoration: underline;
      }
    }
  }

  .Amount form {
    display: initial;

    .Amount__quick-amounts {
      margin: var(--pnp-spacing) auto;
    }

    .Amount__custom-amount,
    .Amount__button {
      display: inline-flex;
    }
    .Amount__custom-amount {
      margin-right: var(--pnp-spacing);
      .tg-input__status-text {
        color: #d63e3e;
        font-weight: 700;
        margin: 0 0 5px;
      }
    }
    button[disabled] {
      opacity: 0.2;
    }

    .Amount__bonus-code-input {
      display: inline-flex;
      margin-right: var(--pnp-spacing);
      margin-top: 0;
    }

    .Amount__bonus-code-button {
      margin-top: 0;
      margin-bottom: var(--pnp-spacing);
    }
    .deposit-confirmation {
      width: 100%;
      order: 1;
      text-align: left;
      margin: 10px 0;

      .flex {
        max-width: 500px;
        margin: 0 auto;
        border: 1px solid #fff;
        color: #fff;
        padding: 20px;
        align-items: flex-start;

        input {
          flex: 1 0 auto;
        }
      }
    }
  }

  header {
    color: var(--white);

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 2em;
      margin: 0 0 10px 0;
    }
  }

  .ExternalAuth {
    height: auto;
  }

  .LowLimitStep,
  .LowLimitStep .link {
    color: var(--white);
  }

  form.DetailsStep {
    flex-direction: column;

    .communication-consent {
      width: auto;
      .flex {
        justify-content: center;
      }

      label {
        width: auto;
      }
    }
  }
  @media (max-width: 540px) {
    .Amount form {
      display: flex;
      flex-flow: wrap;
      align-items: baseline;

      .tg-input {
        min-width: 0;
        width: 100%;
      }

      .Amount__bonus-code-button {
        margin-top: var(--pnp-spacing);
        width: 100%;
      }

      .Amount__bonus-code-input {
        margin-bottom: var(--pnp-spacing);
      }

      .Amount__quick-amounts {
        flex-direction: column;
        margin: 0;
        width: 100%;
        button {
          margin: 5px;
        }
        button:first-child {
          margin-left: 5px;
        }
        button:last-child {
          margin-right: 5px;
        }
      }

      .Amount__custom-amount,
      .button[type='submit'] {
        margin: 5px 5px 0 5px;
        width: 100%;
      }
    }
  }
}
