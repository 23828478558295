.BasePage {
  .error-background {
    .error-content {
      width: initial;
    }

    @media (max-width: 540px) {
      h1 {
        font-size: 30px;
      }
    }
  }
}

#fc_frame:not(.fc-open) {
  @media (min-width: 993px) {
    display: initial;
  }
}
