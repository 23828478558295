.Popup {
  .Popup__content {
    border-radius: 10px;
    color: var(--primary-color);
    
    select {
      option {
        background: none;
      }
    }
  }
  .Popup__header {
    color: var(--white);
  }
  .Popup__children {
    .RealityCheckContainer {
      .actions {
        .button {
          &:last-child {
            width: 100%;
            margin: 15px 0;
            box-shadow: inset 0 0 0 2px var(--primary-color);
            color: var(--primary-color);
          }
        }
      }
    }
  }
  @media (max-width: 993px) {
    .Popup__children {
      font-size: 14px;
    }
  }
}

.LimitConfirmation {
  background: var(--white);
  color: var(--primary-color);
  border-radius: 10px;
  padding: 20px;
  border: 0;
  box-shadow: 3px 2px 8px 0 rgba(0, 0, 0, 0.4);
}

.SuccessfulDepositContainer {
  img {
    width: 15%;
  }
}
