.CasinoGrid,
.GameGrid {
  &__header,
  .grid-header {
    justify-content: unset;
    align-items: center;
    max-width: 94vw;
    margin: 1em auto;
    margin-top: 40px;

    .header-and-icon {
      font-weight: 600;
      font-size: 19.6px;
    }

    a {
      height: 15px;
      margin: 0 15px;
      text-decoration: none;
    }

    @media (max-width: 992px) {
      margin: 1em 0 10px;
      padding: 0 10px;
    }
  }
  .slider {
    .slide-item {
      .last-slide a {
        border-radius: 6px;
      }
    }
    .arrow {
      &--show {
        opacity: 1;
      }

      &:disabled {
        opacity: 0;
      }

      &--next {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        background: linear-gradient(
          to right,
          rgba(0, 0, 0, 0.2) 0%,
          rgba(0, 0, 0, 0.6) 100%
        );

        &:hover {
          background: linear-gradient(
            to right,
            rgba(0, 0, 0, 0.3) 0%,
            rgba(0, 0, 0, 0.7) 100%
          );
        }
      }

      &--prev {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        background: linear-gradient(
          to left,
          rgba(0, 0, 0, 0.2) 0%,
          rgba(0, 0, 0, 0.6) 100%
        );

        &:hover {
          background: linear-gradient(
            to left,
            rgba(0, 0, 0, 0.3) 0%,
            rgba(0, 0, 0, 0.7) 100%
          );
        }
      }

      @media (min-width: 2560px) {
        width: 80px;

        &--next {
          right: 0;
        }

        &--prev {
          left: 0;
        }
      }
    }
  }
}
.CasinoGrid {
  margin: 22px auto;
  max-width: unset;
  &__grid {
    margin: 22px 50px;
  }

  @media (max-width: 992px) {
    margin: 0;

    &__grid {
      margin: 22px 10px;
    }
  }
}

.casino-promotion {
  .container-max-width {
    margin: 0;
    @media (min-width: 992px) {
      padding: 30px 50px;
    }
  }
  .summary {
    h1 {
      margin: 0;
    }
  }
  .jackpot-counter {
    margin-bottom: 10px;
  }
}
