.TournamentsContainer {
  .tournaments-grid {
    .Tournament {
      background: #282828;

      .tournament-content-wrapper {
        .title {
          font-size: 15px;
          margin: 0;
          color: var(--primary-color);
        }

        .summary {
          font-size: 13px;
          margin: 10px 0;
        }
      }
    }
  }
}
