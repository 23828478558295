.notification {
  position: fixed;
  right: 20px;
  top: 130px;
  z-index: 1000;
  width: 378px;
  background: rgb(21, 21, 21);
  overflow: hidden;
  touch-action: none;
  -webkit-user-select: none;
          user-select: none;
}

@media (max-width: 600px) {

.notification {
    width: calc(100% - 20px);
    top: 10px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%)
}
  }

.notification:hover .timeout-bar {
      -webkit-animation-play-state: paused;
              animation-play-state: paused;
    }

.notification .timeout-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: #00a19a;
    -webkit-transform-origin: right;
            transform-origin: right;
    -webkit-animation-name: notification-timeout-bar;
            animation-name: notification-timeout-bar;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }

.notification .timeout-bar--infinite {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-animation: none;
              animation: none;
    }

.notification .content {
    padding: 18px 15px 12px;
  }

.notification .content.has-action {
      cursor: pointer;
    }

.notification .content .icon {
      margin-right: 12px;
      height: 18px;
    }

.notification .content .head {
      display: flex;
      padding-bottom: 5px;
    }

.notification .content .head .title {
        font-size: 14px;
        font-weight: bold;
        align-self: center;
      }

.notification .content .head .date {
        color: #eeeeee;
        font-size: 10px;
        margin-left: 15px;
        align-self: center;
      }

.notification .notification-buttons {
    display: flex;
    flex-direction: row;
  }

.notification .notification-button {
    flex: 1 1;
    display: flex;
    border-top: 1px solid #1b1b1b;
    height: 40px;
    color: #00a19a;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    cursor: pointer;
  }

.notification .notification-button:hover {
      color: #ffffff;
    }

.notification-wrapper-enter {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  opacity: 0.01;
}

.notification-wrapper-enter-active {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  opacity: 1;
  transition: all 200ms ease-out;
}

.notification-wrapper-exit-active {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  opacity: 0.01;
  transition: all 200ms ease-out;
}

.notification-wrapper-exit-active.direction-left {
    left: 0;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }

.notification-wrapper-exit-active.direction-up {
    -webkit-transform: translateY(-100%) translateX(-50%);
            transform: translateY(-100%) translateX(-50%);
  }

@-webkit-keyframes notification-timeout-bar {
  from {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }

  to {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
  }
}

@keyframes notification-timeout-bar {
  from {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }

  to {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
  }
}
