.tg-input,
.tg-password-input,
.tg-phonenumber-input,
.tg-select {
  .Input, 
  .Select {
    transform: skew(-15deg);
    box-shadow: inset 0 0 0 2px var(--primary-color);
    border-radius: 5px;

    input,
    select {
      background: none;
      text-align: center;
      border: none;
      color: var(--white);
      transform: skew(15deg);

      option {
        background-color: var(--dark-gray);
      }

      optgroup {
        background-color: var(--dark-gray);
      }

      &::placeholder {
        color: var(--primary-color);
      }
    }

    &__leading-lane .icon, 
    &__trailing-lane .icon {
      transform: skew(15deg);
    }

    &__trailing-lane {
      > * {
        margin-right: 5px;
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      border: 2px solid var(--primary-color);
      -webkit-text-fill-color: var(--white);
      -webkit-box-shadow: 0 0 0px 1000px #242424 inset;
      transform: none;
      border-radius: 5px;
    }
  }
}

.casino-search .tg-input .Input {
  box-shadow: none;

  input {
    margin-left: 0;
  }
}
