.Footer {
  .topSection {
    .container {
      flex-flow: row;
    }
  }
  .LanguageSelector {
    select {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 600;
    }
  }
  .licence {
    flex-wrap: wrap;

    > * {
      margin: 5px;
    }

    div {
      display: flex !important;

      p a {
        display: flex;
        flex-flow: column;
        text-decoration: none;

        span {
          font-size: 10px;
        }

        svg {
          height: 30px;
          margin-right: 0;
        }
      }
    }
  }
  @media (max-width: 992px) {
    .topSection {
      .container {
        flex-flow: column;
      }
    }
  }
}
