.Game {
  .thumbnail {
    border-radius: 6px;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 6px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), transparent);
    }
  }

  .favorite-heart {
    z-index: 1;
  }

  .jackpot {
    bottom: auto;
  }

  .badges {
    top: 5px;
    bottom: initial;
    flex-wrap: wrap;

    .badge {
      margin-bottom: 5px;
    }
  }

  .badges .badge,
  .ribbon {
    background: var(--primary-gradient);
    color: var(--white);
  }

  .ribbon + .badges {
    flex-direction: column;
  }

  .game-info {
    position: absolute;
    bottom: 0;
    left: 0;
    .live-casino-info {
      .roulette-numbers {
        z-index: 1;
      }
    }
  }

  @media (min-width: 993px) {
    &:hover:not(.is-closed) {
      .thumbnail::after {
        font-family: 'icomoon';
        font-size: 45px;
        content: "\e900";
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  @media (max-width: 992px) {
    .game-info {
      .provider {
        font-size: 11px;
      }
      .title {
        font-size: 12px;
      }
      .live-casino-info {
        margin: 0;
      }
    }
  }
}
