body {
  --gameplay-gamedetails-bgcolor: #00a19a;

  --gameplay-friendly-sign-in-message-height: 0px;
}

.PlayContainer .Footer {
  padding-bottom: 60px;
  margin-bottom: 0;
}

.PlayContainer {
  .button.icon {
    background: none;
    color: var(--white);
  }

  .GamePlay {
    background: var(--light-gray);

    @media (max-width: 992px) {
      .PopUnder {
        top: 0;

        &--closed {
          .PopUnder__content {
            transform: translateY(-100%);
          }
        }
        &__content {
          top: calc(
            var(--gameplay-header-height) + var(--gameplay-topbar-height) +
              var(--gameplay-friendly-sign-in-message-height)
          );
        }
      }
    }
  }

  .GamePlayHeader {
    padding: 5px 10px;
    background: var(--dark-gray);
    &__quick-deposit {
      .custom-amount {
        overflow: initial;
      }
    }

    .MembershipActions {
      .button.primary {
        background: rgb(255, 255, 255);
        color: rgb(0, 161, 154);

        span {
          font-weight: 800;
          font-size: 1.5em;
          padding: 0.3em;
        }
      }
    }
  }

  .GameDetails {
    padding: 5px 10px;
    .GameDetails__categories-nav {
      @media (max-width: 992px) {
        display: block;
      }

      .casino-search {
        &.active {
          flex-direction: row;
          justify-content: inherit;
        }
        .search-input {
          display: flex;
          align-items: center;
          .Input {
            margin: 0 20px;
            transform: skew(-15deg);
            background: var(--white);
            &__status-line {
              background: var(--white);
            }
          }
        }
      }

      input {
        background: none;
        transform: skew(15deg);
      }

      i {
        font-size: 20px;
      }
    }
    .GameDetails__category {
      .last-slide a {
        background: var(--primary-color-hover);
      }
    }
  }

  @media (max-width: 1024px) {
    .GamePlayHeader {
      height: var(--gameplay-header-height);
      &__quick-deposit {
        .QuickDepositMobile {
          i {
            font-size: 25px;
          }
        }
      }
      &__hamburger {
        img {
          height: 18px;
        }
      }
    }
    .GameDetails {
      background: var(--dark-gray);
      &__actions {
        .button {
          background: var(--primary-btn-gradient);
          box-shadow: none;
          margin: 5px;
        }
      }
      &__category {
        .NoGamesMessage {
          background: var(--dark-gray);
        }
      }
      &__categories-nav {
        .casino-search .icon {
          background: none;
        }
      }
    }
  }
}
