.CasinoCategoryMenu {
  &::before {
    display: none;
  }

  .categories-menu {
    .category-item {
      margin: 0;
      height: auto;
      display: flex;
      align-items: center;

      &.active {
        text-decoration: underline;
      }
      img {
        height: 25px;
        margin-right: 5px;
      }
    }
  }

  .casino-search {
    .icon-search,
    .icon-times {
      border-radius: 50%;
      background: #00a19a;
    }

    &.active {
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    .search-input {
      display:flex;
      align-items: center;
      .Input {
        &__status-line {
          background: none;
        }
      }
    }
  }
}

.GamePlay {
  .CasinoCategoryMenu {
    .categories-menu {
      color: var(--white);
    }
  }
}
