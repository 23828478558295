.LanguageSelector {
  margin: 0;
  .tg-select {
    .Select {
      box-shadow: none;
      select { 
        @media (max-width: 992px) {
          font-size: 12px !important;
        }
      }
      &__status-line {
        background: none;
        height: 0px;
      }
      .Input__trailing-lane {
        color: white;
        i {
          font-size: 16px;
        }
      }
    }
  }
}
