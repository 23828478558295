.ErrorBoundary {
  padding: 0;

  .error-message-container {
    text-align: center;
    background: none;

    h1 {
      color: var(--white);
    }
  }
}
