.PromotionDetailContainer {
  .promotion-banner {
    position: relative;

    .banner-image {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: var(--banner-fade);
        z-index: 1;
        pointer-events: none;
      }
    }

    .banner-content {
      position: absolute;
      display: inherit;
      background: none;
      padding: 50px;
      top: 50%;
      transform: translateY(-50%);
      width: 80%;

      .button.primary {
        font-size: 1.3em;
      }
    }
  }

  .promotion-content {
    padding: 50px;
    max-width: 800px;

    .content {
      font-size: 1.3em;
    }
  }
}
