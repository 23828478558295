.Step3 .account-information {
    text-align: left;
    width: 100%;
    margin: 40px 0;
    line-height: 8px;
  }

.Step3 .account-information .account-header {
      margin: 10px 0;
      padding: 0 0 15px;
      text-align: center;
      border-bottom: 2px var(--primary-text-color) dotted;
    }

.Step3 .account-information .account-header .fullname {
        font-size: 22px;
        font-weight: bold;
        color: var(--primary-text-color);
      }

.Step3 .account-information .account-header .icon {
        font-size: 60px;
      }

.Step3 .account-information .user-info {
      padding: 10px 0;
      border-bottom: 2px var(--primary-text-color) dotted;
      line-height: 20px;
    }

.Step3 .account-information .birth-info {
      padding: 10px 0;
      border-bottom: 2px var(--primary-text-color) dotted;
    }

.Step3 .account-information .contact-info {
      padding: 10px 0;
      border-bottom: 2px var(--primary-text-color) dotted;
    }

.Step3 .inner-limit-info p {
      margin: 5px 0;
    }

.Step3 .optin-information-holder .optin-information-wrapper {
      border-bottom: 2px var(--primary-text-color) dotted;
      padding-bottom: 10px;
      text-align: left;
      line-height: 1em;
    }

.Step3 .optin-information-holder .optin-information-wrapper .optin-information {
        width: 100%;
        margin-top: 25px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
      }

.Step3 .final-message {
    margin-top: 20px;
    text-align: left;
  }
