body .SessionSummary {
  .Summary {
    .col {
      .info {
        span:nth-child(2) {
          color: var(--primary-color);
        }
      }

      .date {
        color: var(--primary-color);
      }
    }
  }

  .deposit-limit {
      .limit {
          div:nth-child(2) {
              color: var(--primary-color);
          }
      }
  }
}
