.Banners {
  .slick-slider .slick-list .slick-track .slick-slide {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    .background:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: var(--banner-fade);
      z-index: 1;
      pointer-events: none;
    }
    .content {
      left: 40px !important;
      width: 100%;
      max-width: 1900px;
      padding: 0 20px;
      position: relative;
      margin-bottom: 60px;
      /* reset styles from tg-base */
      top: auto !important;
      bottom: auto !important;
      & h1 {
        font-size: 35px;
      }
      h1,
      h2,
      h3,
      h4 {
        margin: 0;
        line-height: normal;
      }

      @media (max-width: 992px) {
        left: 20px !important;
      }
    }
  }
}
