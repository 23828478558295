.BonusContainer .bonus-code-form button,
.limit-container .timespan-selector .button,
.button,
button {
  &.primary,
  &.primary-button,
  &.secondary,
  &.secondary-button,
  &.tertiary,
  &.tertiary-button {
    transition: none;
    border-radius: 5px;
    transform: skew(-15deg);
    text-transform: uppercase;

    svg circle {
      stroke: var(--white);
    }

    > * {
      transform: skew(15deg);
    }
  }

  &.primary,
  &.primary-button {
    color: var(--primary-btn-color);
    background: var(--primary-btn-gradient);

    &:hover {
      background: var(--primary-btn-background-hover);
    }
  }

  &.secondary,
  &.secondary-button {
    color: var(--secondary-btn-color);
    background: var(--secondary-btn-gradient);

    &:hover {
      background: var(--secondary-btn-background-hover);
    }
  }

  &.tertiary,
  &.tertiary-button {
    color: var(--tertiary-btn-color);
    box-shadow: inset 0 0 0 2px var(--white);
    background: var(--tertiary-btn-background);

    &:hover {
      background: var(--tertiary-btn-background-hover);
    }
  }
  &.icon {
    padding: 0;
    .icon {
      padding: 0 5px;
    }
  }
}

.ConfirmContainer .Popup__content .ConfirmContainer__actions .tertiary.button,
.LimitConfirmation .button-wrapper .tertiary.button{	
  color: var(--secondary-btn-color);	
  background: var(--secondary-btn-gradient);	
  box-shadow: none;	

  &:hover {	
    background: var(--secondary-btn-background-hover);	
  }
}


.limit-container .timespan-selector .button {
  font-size: 0.8em;
  height: 40px;
}

.limit-container .timespan-selector .button:not(.selected) {
  background: var(--secondary-color);

  &:hover {
    background: var(--primary-color);
  }
}

.IndexContainerPnp .index-container-full .actions .button {
  box-shadow: 3px 2px 8px 0 rgba(0, 0, 0, 0.3);
  padding: 0;
  width: 370px;
  font-size: 1.85em;
}

.Banners .button,
.ErrorBoundary .button {
  box-shadow: 3px 2px 8px 0 rgba(0, 0, 0, 0.3);
}

.IndexContainerPnp .PayNPlay {
  .question {
    .button {
      transform: none;
      background: none;
      padding: 0;
      font-weight: normal;
      text-transform: none;
      font-size: inherit;
      text-decoration: underline;

      span {
        transform: none;
        display: inline;
      }
    }
  }
}

.MembershipActions {
  .button {
    &:hover {
      background: var(--primary-btn-gradient);
    }
  }
}
