.notification {
  background: var(--dark-gray);

  .notification-button {
    border-top: 1px solid var(--border-color);
  }

  .icon {
      display: none;
  }
}
