@import './variables.css';
.Dashboard {
  min-height: calc(100% - 42px);
  .notifications-badge--right[data-notifications]:not([data-notifications='']):not([data-notifications='0']):after {
    color: var(--white);
  }

  .ItemWithIconAndText {
    &:hover {
      .icon {
        filter: brightness(1.4);
      }
    }
  }

  .products,
  .profile-links,
  .payment-buttons {
    border-bottom: 1px solid var(--border-color);
  }

  .payment-buttons {
    padding-bottom: 20px;
    order: 2;
  }

  .profile-links {
    order: 3;
  }

  .products {
    order: 4;
  }

  .balance {
    order: 1;
    margin-top: 0;
    align-items: center;
    .refresh-button,
    .refresh-button.loading {
      display: block;
      background: none;
      color: var(--white);
      svg > * {
        stroke: var(--white);
      }
    }
  }

  .support {
    margin-top: auto;
    order: 5;
  }

  .navigation {
    order: 6;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;

    a {
      text-decoration: none;
      padding: 5px 0;

      &:hover {
        text-decoration: underline;
      }
    }

    @media (max-width: 992px) {
      display: flex;
    }
  }

  .footer {
    border-top: 1px solid var(--border-color);
    padding-top: 20px;
    order: 7;
    min-height: 62px;

    .LanguageSelector {
      left: -10px;
    }
  }

  @media (max-width: 540px) {
    .payment-buttons {
      .button {
        margin: 0;

        &:first-child {
          margin-right: 10px;
        }
      }
    }

    .support {
      padding: 20px 0 20px 0;
    }

    .footer {
      padding: 20px 0 0 0;
    }
  }
}