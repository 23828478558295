.AccordionWidget {
  color: var(--white);

  .AccordionItem {
    &__header {
      background-color: var(--accordion);

      &:hover {
        background-color: var(--accordion);
      }

      &__expand-icon {
        color: var(--white);
      }

      .info-amount {
        color: #717171;

        @media (max-width: 540px) {
          right: 50px;
        }
      }

      &__tooltop {
        border-radius: 10px;
      }
    }

    &__content {
      background-color: var(--accordion);

      .table {
        background: var(--accordion);
      }

      .pagination {
        display: flex;
      }

      .navigation {
        display: flex;
        margin-top: 10px;

        button {
          width: 50%;
          margin: 5px;
          padding: 0 1em;
        }
      }
    }

    .tg-list {
      .row {
        .info {
          .info-item {
            line-height: 20px;
            color: var(--tertiary-text-color);

            &:first-child {
              align-items: flex-start;

              > * {
                &:first-child {
                  font-weight: bold;
                  color: var(--secondary-text-color);
                }
              }
            }

            &:last-child {
              align-items: flex-end;

              > * {
                &:first-child {
                  font-weight: bold;
                  color: var(--secondary-text-color);
                }
              }
            }
          }
        }
      }
    }
  }
}
