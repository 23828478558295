.IndexContainerPnp {
  .index__header {
    top: 0px;
  }
  .index-container-full {
    min-height: calc(100vh - 50px);

    .actions {
      .button {
        @media (max-width: 540px) {
          width: 90%;
        }
      }
    }
  }

  .index-background-active + .fixed-position .brand-description,
  .index-background-active + .fixed-position .Features,
  .index-background-active + .fixed-position .Carousel {
    display: none;
  }
}
