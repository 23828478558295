.messages-list {
  .messages-list-item {
    background: var(--accordion);
    padding: 8px 15px;

    .header-row {
      .icon {
        display: none;
      }

      .title {
        margin: 5px 0;
      }

      .time {
        margin: 0;
        padding: 0;
      }
    }

    &:not(.open).messages-list-item:hover {
      background: var(--accordion);
    }

    .message-wrapper {
      padding: 10px 0;
      &:before {
        display: none;
      }
    }
  }
}
