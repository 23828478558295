.header-wrapper {
  .Header__topnav {
    height: var(--header-height);
    display: flex;
    align-items: center;

    .top-nav-menu-item {
      font-weight: bold;
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;

      &.active {
        z-index: 0;

        &::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          bottom: 0;
          left: 0;
          background: var(--primary-gradient);
          transform: skew(-15deg);
          z-index: -1;
        }
      }
    }
  }

  .Header__support {
    margin-right: 40px;
    font-weight: bold;
    font-size: 14px;
  }

  .Header__hamburger {
    .notifications-badge--right[data-notifications]:not([data-notifications='']):not([data-notifications='0']):after {
      color: var(--white);
    }
  }

  @media (max-width: 992px) {
    .Header__topnav {
      display: none;
    }

    .Header__logo {
      margin-right: auto;
    }
  }

  .MembershipActions {
    .button.primary {
      background: rgb(255, 255, 255);
      color: rgb(0, 161, 154);

      &:not(.user-balance) {
        @media (min-width: 992px) and (max-width: 1280px) {
          padding: 0 3em;
          font-size: 1em;
        }
      }

      &:hover {
        background: rgb(198, 255, 252);
      }

      span {
        font-weight: 800;
        font-size: 1.5em;
        padding: 0.3em;
      }
    }

    .line {
      transform: none;
      height: 40px;
      border-left: 1px solid rgb(0, 161, 154);
    }

    .icon-plus {
      padding: 0 10px;
      width: auto;
      height: auto;
    }
    .WalletButtonSkeleton {
      background: var(--primary-btn-gradient);
      transform: skew(-15deg);
    }
  }
}
