.content-container {
  .actual-content {
    margin-top: 30px;
    margin-left: 3%;
  }

  .NavList {
    margin-top: 50px;

    ul {
      border-radius: 10px;
    }
  }
}
