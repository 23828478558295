.SourceOfWealthContainer {
  textarea {
    font-size: 14px;
  }

  hr {
    border: none;
    height: 1px;
    background: var(--primary-color);
  }

  .occupation {
    .alternatives {
      select, 
      input {
        color: var(--primary-color);
      }
    }
  }

  .completed {
    color: var(--primary-color); 
  }

  .button-wrapper {
    .tertiary {
      border: 1px solid var(--black);
      color: var(--black);
      &:hover {
        background: none;
      }
    }
  }

  @media (max-width: 992px) {
    .button-wrapper {
      flex-direction: column;

      .button {
        width: 100%;
        margin: 0;

        &:nth-child(2) {
          margin-top: 10px;
        }
      }
    }
  }
}
