.messages-list {
  flex: 1 1;
  flex-direction: column;
}

.messages-list:hover {
    cursor: pointer;
  }

.messages-list.active .messages-list-item header,
      .messages-list.active .messages-list-item .message {
        opacity: 0.5;
      }

.messages-list.active .messages-list-item.open header,
        .messages-list.active .messages-list-item.open .message,
        .messages-list.active .messages-list-item:not(.read) header,
        .messages-list.active .messages-list-item:not(.read) .message {
          opacity: 1;
        }

.messages-list .pagination {
  text-align: center;
}

.messages-list-item {
  background-color: #1b1b1b;
  border-radius: 2px;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 5px;
  transition: background-color 0.17s linear;
}

.messages-list-item:not(.open).messages-list-item:hover {
    background-color: #1b1b1b;
  }

.messages-list-item:not(.open).messages-list-item:hover .message-wrapper::after {
      background: linear-gradient(transparent, #1b1b1b);
    }

.messages-list-item.open {
    cursor: default;
    padding-bottom: 15px;
  }

.messages-list-item.read .title::after {
      display: none;
    }

.messages-list-item.read header,
    .messages-list-item.read .message {
      opacity: 0.5;
    }

.messages-list-item header,
.messages-list-item .message {
  transition: opacity 0.17s ease;
  opacity: 1;
}

.messages-list-item header img, .messages-list-item .message img {
    max-width: 100%;
  }

.messages-list-item header p, .messages-list-item .message p {
    margin: 0;
    margin-bottom: 12px;
  }

.messages-list-item header {
  padding: 15px 10px 10px;
  cursor: pointer;
}

.messages-list-item header .header-row {
    display: flex;
    align-items: center;
  }

.messages-list-item header .title {
    position: relative;
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    padding-right: 28px;
    word-break: break-word;
  }

.messages-list-item header .title::after {
      content: '';
      position: absolute;
      height: 13px;
      width: 13px;
      right: 10px;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      background-color: #00a19a;
      border-radius: 50%;
    }

.messages-list-item header .icon {
    width: 25px;
    margin-right: 15px;
  }

.messages-list-item header .time {
    color: #eeeeee;
    font-size: 10px;
    margin-left: 40px;
    padding-top: 2px;
    white-space: nowrap;
    text-transform: uppercase;
  }

.messages-list-item header .caret {
    margin-left: auto;
    font-size: 24px;
    -webkit-transform-origin: center;
            transform-origin: center;
    transition: -webkit-transform 0.1s linear;
    transition: transform 0.1s linear;
    transition: transform 0.1s linear, -webkit-transform 0.1s linear;
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
  }

.messages-list-item header .caret.flipIcon {
      -webkit-transform: translateY(-1px) rotate(180deg);
              transform: translateY(-1px) rotate(180deg);
    }

.messages-list-empty-messages {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
}

.messages-list-empty-messages p,
  .messages-list-empty-messages i {
    opacity: 0.7;
  }

.messages-list-empty-messages p {
    margin-top: 8px;
  }

.messages-list-empty-messages i {
    font-size: 60px;
  }

.messages-list-item .message {
    min-height: 50px;
    word-break: break-word;
  }

.messages-list-item .message img {
      height: auto;
      margin-bottom: 12px;
    }

.messages-list-item .message > :first-child {
      margin-top: 0;
      padding-top: 0;
    }

.messages-list-item .message-wrapper {
    position: relative;
    padding: 0 25px 0 50px;
  }

.messages-list-item .message-wrapper::before {
      content: '';
      position: absolute;
      display: block;
      height: 30px;
      z-index: 2;
      width: 100%;
      background: linear-gradient(
        180deg,
        transparent,
        #00a19a 80%,
        #00a19a 100%
      );
      bottom: 0px;
      left: 0;
      transition: background-color 0.3s linear;
    }

.messages-list-item.open .message-wrapper::before {
    display: none;
  }

@media (max-width: 600px) {

.messages-list-item .button {
      width: 100%;
      text-align: center
  }
    }
