body.jurisdiction-sga .landing-page .landing-banner {
  @media (max-width: 992px) {
    min-height: calc(100svh - 145px);
  }
}

.landing-page {
  .content-wrapper {
    padding: 0;
    .actual-content {
      display: none;
    }

    @media (max-width: 1200px) {
      margin-right: 0;
    }
    @media (max-width: 992px) {
      margin-left: 0;
    }
  }

  .PageBuilder > * {
    margin-bottom: 20px;
  }

  main.container .content-container {
    max-width: none;
    margin: 0;
    padding: 0;

    .content {
      display: block;
    }
  }

  .NavList {
    display: none;
  }

  .landing-banner {
    min-height: 75vh;
    max-height: 75vh;
    background-position: center;
    width: 100%;
    position: relative;
    top: 0;

    @media (max-width: 992px) {
      min-height: calc(100svh - 115px);
    }

    .container {
      position: absolute;
      left: 50%;
      z-index: 10;
      transform: translate3d(-50%, 0, 0);
      bottom: 4vh;
      text-align: center;

      @media (max-width: 992px) {
        width: 90%;
      }

      h1,
      h2,
      h3 {
        color: #fff;
        font-size: calc(1em + 1.5vw);
        font-weight: 700;
        line-height: 1em;
        margin: 0;
        max-width: 730px;
        text-transform: uppercase;

        @media (max-width: 992px) {
          font-size: 2em;
        }
      }

      p {
        color: var(--primary-color);
        font-size: 1.4em;
        line-height: 1em;
        font-weight: 700;
        text-transform: uppercase;
      }

      .button {
        margin: 25px 0;
        padding: 2px 35px;
        font-size: 18px;
      }
    }

    &::after {
      content: '';
      position: absolute;
      height: 80%;
      width: 100%;
      left: 0;
      bottom: 0;
      background: linear-gradient(to top, #000, transparent);
    }
  }

  .widget-steps {
    padding: 0 50px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0 auto;
    max-width: 1000px;
    height: 25vh;
    align-items: center;
    justify-content: center;

    @media (max-width: 992px) {
      display: flex;
      list-style-type: none;
      margin: 20px auto;
      padding: 25px 20px;
      align-items: center;
      justify-content: center;
      width: 100%;
      overflow: hidden;

      @media (orientation: landscape) {
        height: initial;
      }
    }

    @media (max-width: 600px) {
      height: 15vh;
      align-items: normal;
    }

    .Section {
      text-align: center;
      padding: 0 20px;

      a {
        text-decoration: none;
      }

      p {
        min-height: 40px;
      }

      &:hover {
        img {
          filter: brightness(1) invert(0);
          transition: all 0.4s;
          transform: translateY(-8px);
        }
      }

      img {
        max-height: 60px;
        filter: brightness(0) invert(1);
        transition: all 0.2s;
        position: relative;
        top: 0;

        @media (max-width: 992px) {
          max-height: 48px;
        }
      }

      @media (max-width: 992px) {
        width: 33.33%;
        padding: 0 20px;
        align-items: center;
        display: flex;
        flex-direction: column;

        p {
          margin: 0;
        }

        &:last-child {
          h2 {
            &:after {
              display: none;
            }
          }
        }
      }

      @media (max-width: 600px) {
        padding: 0 5px;

        p {
          display: none;
        }
      }

      h2 {
        font-size: 4.5em;
        margin: 0;

        @media (max-width: 992px) {
          font-size: 2.2em;
          text-align: center;
        }
      }

      h3 {
        font-size: 1.1em;
        text-transform: uppercase;
        color: var(--primary-color);
        margin-top: 5px;

        @media (max-width: 992px) {
          font-size: 0.8em;
        }

        @media (max-width: 600px) {
          min-height: 30px;
        }
      }
    }
  }

  .landing-terms {
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0 100px 0;

    @media (max-width: 992px) {
      margin: 0 20px;
      padding: 0 15px;
    }

    .container {
      display: flex;
      flex-direction: column;
    }

    ol {
      padding: 0;
    }

    li {
      padding: 10px 0;
    }
  }
}
