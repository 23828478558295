.Alert {
  &.level-success {
    border-color: #1c8906;
    background: rgba(52, 208, 88, 0.15);
  }
  &.level-info {
    border-color: #facf0c;
    background: rgba(250, 207, 12, 0.15);
  }
  &.level-warning {
    border-color: #facf0c;
    background: rgba(250, 207, 12, 0.15);
  }
  &.level-danger {
    border-color: #f9473e;
    background: rgba(249, 71, 62, 0.15);
  }
}
